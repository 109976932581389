import {
  SET_POPULAR_ITEMS_DATA
} from './popularItemsActions';

const initialState = {
  popularItems: []
}

const popularItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POPULAR_ITEMS_DATA:
      return {
        ...state,
        popularItems:action.payload
      };

    default:
      return state;
  }
};

export default popularItemsReducer;
