import React, { Component } from 'react';
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import FooterList from './FooterList';
import FooterListCategoriesContainer from './FooterListCategoriesContainer';
import FooterItem from './FooterItem';
import './Footer.scss';

class Footer extends Component {

  render () {
    return (
      <div className="Footer__wrapper">
        <div className="Container">
          <div className="Footer">
            <div className="Logo__wrapper">
              <Link to="/"
                onClick={
                  () => {
                    animateScroll.scrollToTop({duration: 0})
                  }
                }
              >
                <img src={'/images/logo-sm.svg'} alt="Logo" />
              </Link>
            </div>
            <div 
              className="Footer__col">
              <FooterListCategoriesContainer 
                classes='FooterList__lg'
                heading='Categories'
              />
              <FooterList
                heading='Info'
                categories={
                [
                  {
                    "link":     "/about",
                    "name":     "About us"
                  },
                  {
                    "link":     "/faq",
                    "name":     "FAQs & Support"
                  },
                  {
                    "link":     "/legal",
                    "name":     "Legal"
                  },
                  {
                    "link":     "/contact-us",
                    "name":     "Contact us"
                  }
                ]}
              />
              <FooterList
                heading='For seller'
                categories={
                [
                  {
                    "link":     "/how-to-post",
                    "name":     "How to list"
                  },
                  {
                    "link":     "/how-it-works",
                    "name":     "How it works"
                  }
                ]}
              />
              <FooterList
                classes='FooterList__lg FooterList__networks'
                heading='Connect'
                categories={
                [
                  {
                    "round": "true",
                    "link":     "#",
                    "name":     <i className="fab fa-facebook-f"></i>
                  },
                  {
                    "round": "true",
                    "link":     "#",
                    "name":     <span className="icon icon-instagram"></span>
                  },
                  {
                    "round": "true",
                    "link":     "#",
                    "name":     <i className="fab fa-linkedin-in"></i>
                  }
                ]}
              />
              <FooterItem 
                orderedList={
                  {
                    "single": "true",
                    "round": "true",
                    "link":     "#",
                    "name":     <span className="icon icon-arrow-top"></span>,
                    "scrollDuration": "600"
                  }
                }
              />
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer; 
