import React, { Component } from 'react';
import './SearchInput.scss';
import './SearchSelect.scss';

class SearchSelect extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isOpened: false,
    }

    this.openedToggle =     this.openedToggle.bind(this);
    this.closedToggle =     this.closedToggle.bind(this);
    this.selectCategory =   this.selectCategory.bind(this);
  }

  openedToggle () {
    this.setState((state) => {
      state.isOpened = !state.isOpened;

      return state;
    })
  }

  closedToggle () {
    this.setState((state) => {
      state.isOpened = false;

      return state;
    })
  }

  selectCategory (categoryId) {
    if (categoryId !== this.props.selectedCategoryId) {
      this.props.setCategory('categoryId', categoryId);
    }
    else {
      this.props.removeCategory('categoryId');
    }

    this.openedToggle()
  }

  render () {

    let { placeholder, icon, categories, selectedCategoryId } = this.props;

    let [ selectedCategory ] = categories.filter((category) => {
      return (category.id == selectedCategoryId);
    })

    return (
      <div className="Input__wrapper"
        tabIndex="0"
        onBlur={this.closedToggle}
      >
        {(this.props.icon ? icon : '')}
        <div 
          className="Select SelectSearch"
          onClick={this.openedToggle}
        >
          {
            selectedCategory ?
            selectedCategory.name
            : placeholder
          }
        </div>
        <div className={'SelectMenu' + (this.state.isOpened ? ' open' : '') + ''}>
        {
          categories.length ?
          categories.map((category, k) => {
            return (
              <div
                key={k}
                className={'SelectItem' + ((category.id == selectedCategoryId) ? ' selected' : '')}
                onClick={() => {
                  this.selectCategory(category.id)
                }}
              >
                {category.name}
              </div>
            )
          })
          : null
        }
        </div>
      </div>
    );
  }
}

export default SearchSelect;
