import { connect } from 'react-redux';
import SearchForm from './SearchForm';
import {setFilters} from './../../store/filters/filtersAction';

const mapStateToProps = state => {
  return {
    categories: state.categoriesReducer.categories
  }
};

const mapDispatchToProps = {
  setFilters
};

const SearchFormContainer = connect(mapStateToProps, mapDispatchToProps)(SearchForm);

export default SearchFormContainer;
