import React, { Component } from 'react';

import HowPostSectionContainer from '../sections/HowPostSection/HowPostSectionContainer';

class HowPost extends Component {

  render () {
    return (
      <>
        <HowPostSectionContainer />
      </>
    );
  }
}

export default HowPost;