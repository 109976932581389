import React, { Component } from 'react';
import './FooterItem.scss';
import './FooterHeading.scss';

class FooterHeading extends Component {

  render () {

    return (
      <div className="FooterHeading">
        {this.props.name}
      </div>
    );
  }
}

export default FooterHeading;
