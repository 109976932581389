import { connect } from 'react-redux';
import CardSectionYouLike from './CardSectionYouLike';
import { setRecommendationsData } from './../../store/recommendations/recommendationsAction';

const mapStateToProps = state => {
  return {
    products: state.recommendationsReducer.productsRecomendations
  }
};

const mapDispatchToProps = {
  setRecommendationsData
};

const CardSectionYouLikeContainer = connect(mapStateToProps, mapDispatchToProps)(CardSectionYouLike);

export default CardSectionYouLikeContainer;