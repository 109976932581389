import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import BtnSearch from './BtnSearch';
import SearchSelect from './SearchSelect';
import SearchInput from './SearchInput';
import './SearchForm.scss';

class SearchForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      filters: {
        categoryId: null,
        searchText: '',
        location: ''
      },
      redirect: false
    }

    this.setDataToState =       this.setDataToState.bind(this);
    this.removeDataFromState =  this.removeDataFromState.bind(this);
    this.saveToFilters =        this.saveToFilters.bind(this);
  }

  setDataToState(name, value) {
    this.setState(state => {
      state.filters[name] = value;

      return state;
    })
  }

  removeDataFromState(name) {
    this.setState(state => {
      state.filters[name] = null;

      return state;
    })
  }

  saveToFilters() {
    this.props.setFilters(this.state.filters);

    axios.get('/json/send-data.json', {
      params: this.state.filters
    })
    .then((response) => {
      if (response.data.status == 200) {
        this.setState(state => {

          state.redirect = true;
          window.scrollTo(0, 0);
          
          return state;
        });
      }
    })
    .catch(function (error) {
      
    });
  }

  render () {

    return (

      <div className="SearchForm">
        <SearchSelect 
          categories={this.props.categories} 
          icon={<span className="icon icon-list"></span>}
          placeholder="Categories"
          selectedCategoryId={this.state.filters.categoryId} 
          setCategory={this.setDataToState} 
          removeCategory={this.removeDataFromState} 
        />
        <SearchInput 
          placeholder="I’m looking for…"
          name="searchText"
          value={this.state.filters.searchText}
          setValue={this.setDataToState}
        />
        <SearchInput 
          icon={<span className="icon icon-marker"></span>}
          placeholder="Location…" 
          name="location"
          value={this.state.filters.location}
          setValue={this.setDataToState}
        />
        <BtnSearch
          searchFormData={this.state.filters}
          saveToFilters={this.saveToFilters}
        />
        {
          this.state.redirect ?
          <Redirect push to="/catalog" />
          :
          null
        }
      </div>
    );
  }
}

export default SearchForm; 