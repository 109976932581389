import React, { Component } from 'react';

import LegalSectionContainer from '../sections/LegalSection/LegalSectionContainer';

class Legal extends Component {

  render () {
    return (
      <>
        <LegalSectionContainer />
      </>
    );
  }
}

export default Legal;