import React, { Component } from 'react';
import Btn from './../../components/Btn/Btn';
import './CTASection.scss';

class CTASection extends Component {

  render() {
    return (
      <div className={"CTASection__wrapper" + (this.props.withButton ? " margins" : "")}>
        <div className="Container">
          <div className="CTASection">
            <div className="CTASection__col">
              <h2>
                Sell your goods at&nbsp;Global Farm!
              </h2>
              {
                this.props.withButton ?
                <Btn 
                  color="primary" 
                  Btn={this.props.CTAButton} 
                />
                : null
              }
            </div>
            <div className="CTASection__col">
              <img 
                className="CTASection__img" 
                src={"/images/img-CTA.png"} 
                alt="Global Farm site" 
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CTASection;
