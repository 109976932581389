import React, { Component } from 'react';
import './BtnSubmit.scss';

class BtnSubmit extends Component {

  render () {
    
    return (
      <div
        className={'Btn BtnSubmit ' + this.props.color +''}
        onClick={this.props.onClick}
        >
        {this.props.name}
      </div>
    );
  }
}

export default BtnSubmit;
