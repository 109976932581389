import React, { Component } from 'react';
import axios from 'axios';
import Heading from './../../components/Heading/Heading';
import HowPostBlock from './HowPostBlock';
import './HowPostSection.scss';

class HowPostSection extends Component {

  componentDidMount () {

    axios.get('/json/data-how-post.json')
    .then((response) => {
      this.props.setHowPostData(response.data.dataHowPost);
    })
    .catch(function (error) {
      
    });
  }

  render() {

    return (
      <div className="Container offset-top">
        <div className="HowPostSection">
          <Heading text="How to post" />
          <div className="HowPostSection__content">
            {
            this.props.blocks.length ?
              this.props.blocks.map((block, k) => {
                return (<HowPostBlock key={k} block={block} />)
              })
              : null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default HowPostSection;
