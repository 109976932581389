import React, { Component } from 'react';

import GoogleMap from 'google-map-react';
import Placeholder from './Placeholder';
import './Map.scss';

class Map extends Component {

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11,
    greatPlaceCoords: {
      lat: 59.724465, 
      lng: 30.080121
    }
  };

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <div className="offset-top">
        <div className="Map">
          <GoogleMap
            apiKey={"AIzaSyBpS08Ra4kWpW05b424wX-9Ar4Vxr2nErY"}
            center={this.props.center}
            zoom={this.props.zoom}
            >
            <Placeholder lat={this.props.greatPlaceCoords.lat} lng={this.props.greatPlaceCoords.lng} />
          </GoogleMap> 
        </div>
      </div>
    );
  }
}

export default Map; 