import {
  SET_ABOUT_DATA
} from './aboutAction';

const initialState = {
  dataAbout: []
}

const aboutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ABOUT_DATA: 
      return {
        ...state,
        dataAbout: action.payload
      };

      default:
        return state;
  }
};

export default aboutReducer;