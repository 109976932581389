import React, { Component } from 'react';

import ContactUsSectionContainer from '../sections/ContactUsSection/ContactUsSectionContainer';
import Map from './../components/Map/Map';
import ContactUsForm from './../sections/ContactUsForm/ContactUsForm';
import Modal from './../components/Modal/Modal';

class ContactUs extends Component {

  constructor (props) {
    super(props);
    this.state = {
      modalVisible: false,
      modalType: 'success',
    }

    this.hideModal =        this.hideModal.bind(this);
    this.openModal =        this.openModal.bind(this);
  }

  hideModal () {
    this.setState((state) => {
      state.modalVisible = !state.modalVisible;

      return state;
    })
  }

  openModal (type) {
    this.setState((state) => {
      state.modalType = type;
      state.modalVisible = !state.modalVisible;

      return state;
    })
  }

  render () {
    return (
      <div className="Modal__wrapper">
        <div className={(this.state.modalVisible ? 'hidden' : '')}>
          <ContactUsSectionContainer />
          <Map />
          <ContactUsForm
            openModal={this.openModal}
          />
        </div>
        {
          this.state.modalVisible ?
          <Modal 
            modalType={this.state.modalType}
            redirect="/"
            onClick={this.hideModal}
          />
          :
          null
        }
      </div>
    );
  }
}

export default ContactUs;