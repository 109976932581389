import React, { Component } from 'react';
import './Contact.scss';

class Contact extends Component {

  render () {

    let {title, text, linkHref} = this.props.contact;

    return (
      <div className="Contact__wrapper">
        <div className="Contact">
          <div className="Contact__title">
            {title}
          </div>
          <a className="Contact__text" href={linkHref}>
            {text}
          </a>
        </div>
      </div>
    );
  }
}

export default Contact;