import React, { Component } from 'react';
import Btn from './../Btn/Btn';

class HeaderBlock_unauthorized extends Component {

  render () {
    return (
      <>
        <Btn 
          color='secondary' 
          classes="Btn-sm"
          Btn={this.props.signinBtn} 
        />
          or
        <Btn 
          color='secondary' 
          classes="Btn-sm"
          Btn={this.props.registerBtn} 
        />
      </>
    );
  }
}

export default HeaderBlock_unauthorized;