import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import './Recaptcha.scss';

class Recaptcha extends Component {

  render () {
    return (
      <div className="Recaptcha__wrapper">
        <div className={"Recaptcha__description"+ (this.props.isValid == false ? ' invalid' : '')}>
          { this.props.description }
        </div>
        <div className="Recaptcha">
          <ReCAPTCHA
            sitekey="6LdU0tsUAAAAANnyjOkOWQPqLvTvBIGH7IrTH4ad"
            onChange={this.props.onChange}
          />
        </div>
      </div>
    );
  }
}

export default Recaptcha;