import { connect } from 'react-redux';
import ImageList from './ImageList';
import { setPopularCategoriesData } from './../../store/popularCategories/popularCategoriesAction';

const mapStateToProps = state => {
  return {
    productsPopularCategories: state.popularCategoriesReducer.productsPopularCategories
  }
};

const mapDispatchToProps = {
  setPopularCategoriesData
};

const ImageListContainer = connect(mapStateToProps, mapDispatchToProps)(ImageList);

export default ImageListContainer;
