import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Modal.scss';

class Modal extends Component {

  componentDidMount() {
    window.scrollTo(0, 187);
  }

  render() {

    return (
      <div className={'Modal'
      + (this.props.modalType == 'success' ? ' ModalSuccess' : ' ModalError')}>
        <div className="ModalSuccess__content">
          <div className="Modal__icon__wrapper">
          <Link to={this.props.redirect}>
            <span className="Modal__icon icon icon-close"></span>
          </Link>
          </div>
            <div className="Modal__content">
              <img className="Modal__img" src="./images/modal-img/icon-success.svg" alt=""></img>
              <div className="Modal__text">
                Email sent
              </div>
          </div>
        </div>
        <div className="ModalError__content">
          <div className="Modal__icon__wrapper">
            <span 
              className="Modal__icon icon icon-close"
              onClick={this.props.onClick}
            ></span>
          </div>
            <div className="Modal__content">
              <img className="Modal__img" src="./images/modal-img/icon-error.svg" alt=""></img>
              <div className="Modal__text">
                Please try again
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;