import React, { Component } from 'react';
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import './Image.scss';

class Image extends Component {

  render () {

    let { img, link, name, posts} = this.props.product;

    return (
      <Link to={link}
        className="Image"
        onClick={
          () => {
            animateScroll.scrollToTop({duration: 0})
          }
        }
      >
        <div
          className="Image__img" 
          style={{ backgroundImage: 'url(/images/categories/' + img + ')'}}
        ></div>
        <div className="Image__body">
          <div className="Image__row">
            <div className="Image__title" href={link}>
              {name}
            </div>
            <div className="Image__text">
              {posts} posts
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default Image;