import { 
  SET_ACCOUNT_DATA
} from './accountAction';

const initialState = {
  account: {
    userId: null,
    userName: null,
    userMessages: null,
    userNotifications: null,
  }
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT_DATA:
      return {
        ...state,
        account: action.payload
      };

    default:
      return state;
  }
};

export default accountReducer;
