import {
  SET_RECOMMENDATIONS_DATA
} from './recommendationsAction';

const initialState = {
  productsRecomendations: []
}

const recommendationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECOMMENDATIONS_DATA:
      return {
        ...state,
        productsRecomendations: [...action.payload]
      };

      default:
        return state;
  }
}

export default recommendationsReducer;