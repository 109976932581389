import React, { Component } from 'react';
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import './Item.scss';

class Item extends Component {

  render () {

    let { itemName, itemLink } = this.props.item;
    return (
      <div className="Item">
        <Link 
          to={itemLink} 
          className="Item__link"
          onClick={
            () => {
              animateScroll.scrollToTop({duration: 0})
            }
          }
        >
          {itemName}
        </Link>
      </div>
    );
  }
}

export default Item;