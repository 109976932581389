import React, { Component } from 'react';
import axios from 'axios';
import FAQBlock from './FAQBlock';
import Heading from './../../components/Heading/Heading';
import './FAQSection.scss';

class FAQSection extends Component {

  componentDidMount () {

    axios.get('/json/data-faq.json')
    .then((response) => {
      this.props.setFAQData(response.data.dataFAQ);
    })
    .catch(function (error) {
      
    });
  }

  render() {

    return (
      <div className="Container offset-top">
        <div className="FAQSection">
          <Heading text="FAQs & Support" />
          <div className="FAQSection__content">
          {
          this.props.blocks.length ?
            this.props.blocks.map((block, k) => {
              return (<FAQBlock key={k} block={block} />)
            })
            : null
          }
          </div>
        </div>
      </div>
    );
  }
}

export default FAQSection;
