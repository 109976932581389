import React, { Component } from 'react';

import HowItWorksSectionContainer from '../sections/HowItWorksSection/HowItWorksSectionContainer';

class HowItWorks extends Component {

  render () {
    return (
      <>
        <HowItWorksSectionContainer />
      </>
    );
  }
}

export default HowItWorks;