import { connect } from 'react-redux';
import CardSectionRecommendations from './CardSectionRecommendations';
import { setRecommendationsData } from './../../store/recommendations/recommendationsAction';

const mapStateToProps = state => {
  return {
    products: state.recommendationsReducer.productsRecomendations
  }
};

const mapDispatchToProps = {
  setRecommendationsData
};

const CardSectionRecommendationsContainer = connect(mapStateToProps, mapDispatchToProps)(CardSectionRecommendations);

export default CardSectionRecommendationsContainer;