import {
  SET_POPULAR_CATEGORIES_DATA
} from './popularCategoriesAction';

const initialState = {
  productsPopularCategories: []
}

const popularCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POPULAR_CATEGORIES_DATA: 
      return {
        ...state,
        productsPopularCategories: action.payload
      };

      default:
        return state;
  }
};

export default popularCategoriesReducer;