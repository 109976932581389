import React, { Component } from 'react';
import axios from 'axios';
import Heading from './../../components/Heading/Heading';
import HowItWorksBlock from './HowItWorksBlock';
import './HowItWorksSection.scss';

class HowItWorksSection extends Component {

  componentDidMount () {

    axios.get('/json/data-how-works.json')
    .then((response) => {
      this.props.setHowWorksData(response.data.dataHowWorks);
    })
    .catch(function (error) {
      
    });
  }

  render() {

    return (
      <div className="Container offset-top">
        <div className="HowItWorksSection">
          <Heading text="How it works" />
          <div className="HowItWorksSection__content">
            {
            this.props.blocks.length ?
              this.props.blocks.map((block, k) => {
                return (<HowItWorksBlock key={k} block={block} />)
              })
              : null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default HowItWorksSection;
