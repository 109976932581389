import React, { Component } from 'react';
import axios from 'axios';
import AboutBlock from './AboutBlock';
import './AboutSection.scss';

class AboutSection extends Component {

  componentDidMount () {

    axios.get('/json/data-about.json')
    .then((response) => {
      this.props.setAboutData(response.data.dataAbout);
    })
    .catch(function (error) {
      
    });
  }

  render() {

    return (
      <div className="Container">
        <div className="AboutSection">
          {
          this.props.blocks.length ?
            this.props.blocks.map((block, k) => {
              return (<AboutBlock key={k} block={block} />)
            })
            : null
          }
        </div>
      </div>
    );
  }
}

export default AboutSection;
