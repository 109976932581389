import { connect } from 'react-redux';
import HowPostSection from './HowPostSection';
import { setHowPostData } from './../../store/dataHowPost/howPostAction';

const mapStateToProps = state => {
  return {
    blocks: state.howPostReducer.dataHowPost
  }
};

const mapDispatchToProps = {
  setHowPostData
};

const HowPostSectionContainer = connect(mapStateToProps, mapDispatchToProps)(HowPostSection);

export default HowPostSectionContainer;