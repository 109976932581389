import React, { Component } from 'react';
import './BtnSearch.scss';

class BtnSearch extends Component {

  render () { 
    
    return (
      <div 
        className='BtnSearch'
        onClick={() => {
          this.props.saveToFilters();
        }}
      >
        <span className="icon icon-search"></span>
        Search
      </div>
    );
  }
}

export default BtnSearch;
