import { 
  SET_FILTERS_DATA,
  RESET_FILTERS_DATA
} from './filtersAction';

const initialState = {
  categoryId: null,
  searchText: null,
  location: null,
  priceRange: null,
  sortOption: null,
};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS_DATA:
      return {
        ...state,
        ...action.payload.filters
      };

    case RESET_FILTERS_DATA:
      return initialState;

    default:
      return state;
  }
};

export default filtersReducer;
