import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import {validateFieldsUtil, changeState} from './../../utils/utilsForm';
import LocationSearchInput from './../../components/Inputs/InputSearchLocation';
import Heading from './../../components/Heading/Heading';
import BtnSubmit from './../../components/BtnSubmit/BtnSubmit';
import InputWithLabel from './../../components/Inputs/InputWithLabel';
import Select  from './../../components/Inputs/Select';
import Radio from './../../components/Inputs/Radio';
import './SetUpSection.scss';

class SetUpSection extends Component {

  constructor (props) {
    super(props);
    this.state = {
      accountTypes: [
        'Individual',
        'Business',
        'Both'
      ],
      fields: {
        firstName: '',
        lastName: '',
        location: '',
        accountType: '',
        optionId: null,
        businessName: '',
        businessCompanyNumber: '',
      },
      validatesForFields: {
        firstName: ['required'],
        lastName: ['required'],
        location: ['required'],
        accountType: ['required'],
        optionId: [],
        businessName: [],
        businessCompanyNumber: [],
      },
      isValidFields: {
        firstName: null,
        lastName: null,
        location: null,
        accountType: null,
        optionId: true,
        businessName: true,
        businessCompanyNumber: true,
      },
      errorFields: {
        firstName: null,
        lastName: null,
        location: null,
        accountType: null,
        optionId: null,
        businessName: null,
        businessCompanyNumber: null,
      },
      fieldFocused: null,
      redirect: false
    }

    this.setDataToField =       this.setDataToField.bind(this);
    this.removeDataFromField =  this.removeDataFromField.bind(this);
    this.handleValidation =     this.handleValidation.bind(this);
    this.sendData =             this.sendData.bind(this);
  }

  componentDidMount () {

    axios.get('/json/business-types.json')
    .then((response) => {
      this.props.setBusinessTypesData(response.data.businessTypes);
    })
    .catch(function (error) {
      
    });
  }

  setDataToField(name, value) {
    this.setState(state => {

      changeState (state, name, value);

      return state;
    })
  }

  removeDataFromField(name) {
    this.setState(state => {
      state.fields[name] = null;

      return state;
    })
  }

  handleValidation(redirectOnSuccess){

    this.setState(state => {

      let stateValidated = validateFieldsUtil(state);

      return stateValidated;
    }, () => {

      let isValidFields = true;

      for (let fieldName in this.state.isValidFields) {

        if (!this.state.isValidFields[fieldName]) {
          isValidFields = false;
          break;
        }
      }

      if (isValidFields) {
        this.sendData(redirectOnSuccess);
      }
    });
  }

  sendData(redirectOnSuccess) {
    axios.get('/json/account.json', {
      params: this.state.fields
    })
    .then((response) => {
      if (response.data.status == 200) {
        this.props.setAccount(response.data.account);
        this.setState(state => {

          state.redirect = redirectOnSuccess;
          window.scrollTo(0, 0);
          
          return state;
        });
      }
    })
    .catch(function (redirectOnError) {
      this.setState(state => {

        state.redirect = redirectOnError;
        window.scrollTo(0, 0);
        
        return state;
      });
    });
  }

  render() {

    return (
      <div className="Container offset-top">
        <div className="SetUpSection">
          <Heading text="Set-up your profile" />
          <form className="Form">
            <div className="InputWithLabel__row">
              <InputWithLabel
                label="First name"
                type="text"
                classes="" 
                isValid={this.state.isValidFields.firstName} 
                errorMessage={this.state.errorFields.firstName} 
                placeholder=""
                name="firstName"
                value={this.state.fields.firstName}
                setValue={this.setDataToField}
              />
              <InputWithLabel 
                label="Last name" 
                type="text" 
                classes=""
                isValid={this.state.isValidFields.lastName} 
                errorMessage={this.state.errorFields.lastName} 
                placeholder="" 
                name="lastName"
                value={this.state.fields.lastName}
                setValue={this.setDataToField}
              />
            </div>
            <div className="InputWithLabel__row">
              <LocationSearchInput 
                label="My location" 
                type="text"
                classes="w-100"
                isValid={this.state.isValidFields.location} 
                errorMessage={this.state.errorFields.location} 
                icon={<span className="icon icon-marker"></span>}
                placeholder="Select" 
                name="location"
                value={this.state.fields.location}
                setValue={this.setDataToField} 
              />
            </div>
            <div className="Radio__block">
              <div className="Radio__row">
                <label>
                  Are you a 
                </label>
                {
                  this.state.accountTypes.map(accountType => {
                    return <Radio
                      key={accountType}
                      label={accountType}
                      classes=""
                      isValid={this.state.isValidFields.accountType}
                      name="accountType"
                      value={accountType}
                      checked={this.state.fields.accountType}
                      setValue={this.setDataToField}
                    />
                  })
                }
                
              </div>
              <span className="Invalid-messege">
                {this.state.errorFields.accountType}
              </span>
            </div>
            <div className="InputWithLabel__row">
              <Select
                label="Business type"
                options={this.props.options}
                classes="" 
                isValid={this.state.isValidFields.optionId} 
                errorMessage={this.state.errorFields.optionId} 
                placeholder="Choose"
                name="businessType"
                selectedOptionId={this.state.fields.optionId} 
                setOption={this.setDataToField} 
                removeOption={this.removeDataFromField} 
              />
              <InputWithLabel 
                label="Business name" 
                type="text" 
                classes=""
                isValid={this.state.isValidFields.businessName} 
                errorMessage={this.state.errorFields.businessName} 
                placeholder="" 
                name="businessName"
                value={this.state.fields.businessName}
                setValue={this.setDataToField}
              />
            </div>
            <div className="InputWithLabel__row">
              <InputWithLabel 
                label="Business/Company Number" 
                classesLabel="w-50"
                type="text"
                classes=""
                isValid={this.state.isValidFields.businessCompanyNumber} 
                errorMessage={this.state.errorFields.businessCompanyNumber} 
                placeholder="" 
                name="businessCompanyNumber"
                value={this.state.fields.businessCompanyNumber}
                setValue={this.setDataToField}
              />
            </div>
            <div className="Btn__wrapper">
              <BtnSubmit
                color="primary"
                name="Start browsing"
                onClick={() => {
                  this.handleValidation('/')
                }}
              />
              <BtnSubmit
                color="thirdly"
                name="Personalize your account"
                onClick={() => {
                  this.handleValidation('/')
                }}
              />
              {
                this.state.redirect ?
                <Redirect push to={this.state.redirect} />
                :
                null
              }
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SetUpSection;
