import React, { Component } from 'react';

import SearchFormSection from './../sections/SearchFormSection/SearchFormSection';
import CatalogSection from './../sections/CatalogSection/CatalogSection';
import AdvantageSectionContainer from './../sections/AdvantageSection/AdvantageSectionContainer';

class Catalog extends Component {

  render () { 
    return (
      <>
        <SearchFormSection />
        <CatalogSection />
        <AdvantageSectionContainer />
      </>
    );
  }
}

export default Catalog;