import React, { Component } from 'react';
import Radio from './../../components/Inputs/Radio';
import './FitersSection.scss';

class FitersSection extends Component {
  
  constructor (props) {
    super(props);
    this.state = {
      priceRanges: [
        'All',
        10,
        20,
        30,
        40,
      ],
      priceRanges: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'less $10',
          value: 10,
        },
        {
          label: 'less $25',
          value: 25,
        },
        {
          label: 'less $50',
          value: 50,
        },
        {
          label: 'less $70',
          value: 70,
        },
      ],
      sortOptions: [
        {
          label: 'Default',
          value: '',
        },
        {
          label: 'Price: lowest first',
          value: 'asc',
        },
        {
          label: 'Price: highest first',
          value: 'desc',
        },
      ],
      filters: {
        priceRange: '',
        sortOption: '',
      }, // ПЕРЕПИСАТЬ!!! НЕ СБРАСЫВАЕТСЯ СОРТИРОВКА
    }

    this.setDataToField =     this.setDataToField.bind(this);
  }

  setDataToField(name, value) {
    
    this.props.setFilters({
      [name]: value
    });
  }

  render () {
    return (
      <div className="FitersSection">
        <div className="FitersSection__title">
          Refine your search
        </div>
        <div className="Fiter">
          <div className="Radio__block">
            <div className="Radio__row">
              <label>
                Price
              </label>
              {
                this.state.priceRanges.map((priceRange, k) => {
                  return <Radio
                    key={k}
                    label={priceRange.label}
                    classes=""
                    name="priceRange"
                    value={priceRange.value}
                    checked={this.props.filters.priceRange}
                    setValue={() => {
                      this.setDataToField('priceRange', priceRange.value)
                    }}
                  />
                })
              }
            </div>
          </div>
        </div>
        <div className="FitersSection__title">
          Sort your list
        </div>
        <div className="Fiter">
          <div className="Radio__block">
            <div className="Radio__row">
              <label>
                Sort by
              </label>
              {
                this.state.sortOptions.map((sortOption, k) => {
                  return <Radio
                    key={k}
                    label={sortOption.label}
                    classes=""
                    name="sortOption"
                    value={sortOption.value}
                    checked={this.props.filters.sortOption}
                    setValue={() => {
                      this.setDataToField('sortOption', sortOption.value)
                    }}
                  />
                })
              }
            </div>
          </div>
        </div>
        <div className="FitersSection__clear">
          <div 
            className="Btn primary"
            onClick={this.props.resetFilters}
          >
            Clear all
          </div>
        </div>
      </div>
    );
  }
}

export default FitersSection;