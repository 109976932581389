import React, { Component } from 'react';
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import './FooterItem.scss';

class FooterItemCategories extends Component {

  render () {

    let { id, name, link, round, single, scrollDuration } = this.props.item;
    return (
      <Link 
        to={link}
        className={"FooterItem " + (round ? "FooterItem__round " : "") + (single ? "go-top" : "")} 
        onClick={
          this.props.onClick,
          () => {
            animateScroll.scrollToTop({duration: (scrollDuration ? scrollDuration : 0)});
            this.props.saveToFilters({ categoryId: id });
          }
        }
      >
        {name}
      </Link>
    );
  }
}

export default FooterItemCategories;
