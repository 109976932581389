import React, { Component } from 'react';
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import './Advantage.scss';

class Advantage extends Component {

  render () {

    let { img, title, text, linkHref, linkName } = this.props.advantage;
    return (
      <div className="Advantage">
        <div className="Advantage__img">
          <img src={'/images/advantages/' + img + ''} alt="" />
        </div>
        <div className="Advantage__title">
          {title}
        </div>
        <div className="Advantage__text">
          {text}
        </div>
        <Link to={linkHref}
          className="Advantage__link"
          onClick={
            () => {
              animateScroll.scrollToTop({duration: 0})
            }
          }
        >
          {linkName}
        </Link>
      </div>
    );
  }
} 

export default Advantage;