import React, { Component } from 'react';
import './Radio.scss';

class Radio extends Component {

  render () {

    let { icon, classes, name, label, value, checked, setValue, isValid} = this.props; 

    let isChecked = checked == value;
    
    return (
      <div className="Radio__wrapper">
        
        <label className={'Radio__label ' + (isChecked ? 'checked' : '') + (isValid == false ? ' invalid' : '')}>
          <input 
            type="radio" 
            className={'Radio__input' + classes + (icon ? ' Input_with-icon' : '')}
            value={value}
            checked={isChecked}
            onChange={(event) => {
              setValue(name, event.target.value)
            }}
          />
          {label}
        </label>
      </div>
    );
  }
}

export default Radio;
