import React, { Component } from 'react';
import axios from 'axios';
import Advantage from './Advantage';
import './Advantage.scss';
import './AdvantageSection.scss';

class AdvantageSection extends Component {

  componentDidMount () {

    axios.get('/json/advantages.json')
    .then((response) => {
      this.props.setAdvantagesData(response.data.advantages);
    })
    .catch(function (error) {
    });
  };

  render () {
    return (
      <div className="Container offset-top">
        <div className="AdvantageSection">
          {
            this.props.advantages.length ?
            this.props.advantages.map(advantage => {
              return (<Advantage key={advantage.id} advantage={advantage} />)
            })
            : null
          }
        </div>
      </div>
    );
  }
}

export default AdvantageSection;