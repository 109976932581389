import React, { Component } from 'react';
import './NoResultSection.scss';

class NoResultSection extends Component {

  render () {
    return (
      <div className="NoResultSection">
        <div className="NoResultSection__title">
          NO RESULTS
        </div>
        <img src="./images/no_data.svg" alt="No Results Found"></img>
      </div>
    );
  }
}

export default NoResultSection;