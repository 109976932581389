import { connect } from 'react-redux';
import FooterListCategories from './FooterListCategories';
import {setFilters} from './../../store/filters/filtersAction';

const mapStateToProps = state => {
  return {
    categories: state.categoriesReducer.categories
  }
};

const mapDispatchToProps = {
  setFilters
};

const FooterListCategoriesContainer = connect(mapStateToProps, mapDispatchToProps)(FooterListCategories);

export default FooterListCategoriesContainer;
