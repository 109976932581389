import React, { Component } from 'react';
import axios from 'axios';
import {Redirect } from "react-router-dom";
import GoogleLogin from 'react-google-login';
import './BtnGoogleLogin.scss';

class BtnGoogleLogin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      googleResponse: {},
      redirect: false,
    }

    this.successRespGoogle =   this.successRespGoogle.bind(this);
    this.failureRespGoogle =   this.failureRespGoogle.bind(this);
  }
  
 successRespGoogle = (response) => {

    this.setState(state => {
      state.googleResponse = response;
      return state;
    });

    axios.get('/json/account.json', {
      params: this.state.googleResponse
    })
    .then((response) => {
      if (response.data.status == 200) {
        this.props.setAccount(response.data.account);
        this.setState(state => {

          state.redirect = true;
          window.scrollTo(0, 0);
          
          return state;
        });
      }
    })
    .catch(function (error) {
      
    });
  }
  
  failureRespGoogle = (response) => {
    console.log(response);
  }

  render () {
    
    return (
      <>
        <GoogleLogin
          clientId="658709872495-eg5sd5vdln0o2v392rct156kk4cjs91p.apps.googleusercontent.com"
          className="BtnGoogleLogin"
          onSuccess={this.successRespGoogle}
          onFailure={this.failureRespGoogle}
          cookiePolicy={'single_host_origin'}
          icon={false}
          >
          <img src="./images/google.svg" alt=""></img>
          Sign in with Google
        </GoogleLogin>
        {
          this.state.redirect ?
          <Redirect push to="/" />
          :
          null
        }
      </>
    );
  }
}

export default BtnGoogleLogin;
