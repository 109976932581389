import { connect } from 'react-redux';
import ContactUsSection from './ContactUsSection';
import { setContactUsData } from './../../store/dataContactUs/contactUsAction';

const mapStateToProps = state => {
  return {
    contacts: state.contactUsReducer.dataContactUs
  }
};

const mapDispatchToProps = {
  setContactUsData
};

const ContactUsSectionContainer = connect(mapStateToProps, mapDispatchToProps)(ContactUsSection);

export default ContactUsSectionContainer;