import React, { Component } from 'react';
import TextBlock from './../../components/TextBlock/TextBlock';
import './HowPostBlock.scss';

class HowPostBlock extends Component {

  render () {

    let { img, title, text} = this.props.block;

    return (
      <div className="HowPostSection__row">
        <div className="HowPostSection__col col-img">
          <img 
            className="HowPostSection__img" 
            src={'/images/how-post/' + img + ''} 
            alt="" 
          />
        </div>
        <div className="HowPostSection__col">
          <TextBlock 
            classes="border-bottom"
            heading={title}
            text={text}
          />
        </div>
      </div>
    );
  }
}

export default HowPostBlock;