import React, { Component } from 'react';
import './App.scss';
import { Switch, Route } from "react-router-dom";

import HeaderContainer from './components/Header/HeaderContainer';
import Footer from './components/Footer/Footer';

import Home from       './pages/Home';
import About from      './pages/About';
import FAQ from        './pages/FAQ';
import Legal from      './pages/Legal';
import ContactUs from  './pages/ContactUs';
import HowItWorks from './pages/HowItWorks';
import HowPost from    './pages/HowPost';
import SignUp from     './pages/SignUp';
import ConfirmAccount from  './pages/ConfirmAccount';
import SetUp from           './pages/SetUp';
import SignIn from          './pages/SignIn';
import ResetPassword from   './pages/ResetPassword';
import CreatePassword from  './pages/CreatePassword';
import Catalog from         './pages/Catalog';
import ProductCard from     './pages/ProductCard';

class App extends Component {

  constructor (props) {
    super(props);
    this.state = {
      headerButtons: {
        signinBtn: {
          "name": "Sign in",
          "link": "/sign-in"
        },
        registerBtn: {
          "name": "Register",
          "link": "/sign-up"
        },
        postBtn: {
          "name": "Post an Ad",
          "link": "/how-to-post"
        },
        basketBtn: {
          "name": "My Grocery Basket",
          "link": "#"
        }
      },
      CTAButton: {
        Btn: {
          "name": "Post your goods now",
          "link": "/how-to-post"
        }
      }
    }
  }

  render () {
    return (
      <>
        <HeaderContainer
          signinBtn={this.state.headerButtons.signinBtn}
          registerBtn={this.state.headerButtons.registerBtn}
          postBtn={this.state.headerButtons.postBtn}
          basketBtn={this.state.headerButtons.basketBtn}
        /> 
        <Switch>
          <Route exact path="/">
            <Home
              subheading="Popular items"
              CTAButton={this.state.CTAButton.Btn}
            />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/legal">
            <Legal />
          </Route>
          <Route path="/contact-us">
            <ContactUs/>
          </Route>
          <Route path="/how-it-works">
            <HowItWorks />
          </Route>
          <Route path="/how-to-post">
            <HowPost />
          </Route>
          <Route path="/sign-up">
            <SignUp />
          </Route>
          <Route path="/confirm-account">
            <ConfirmAccount />
          </Route>
          <Route path="/set-up">
            <SetUp />
          </Route>
          <Route path="/sign-in">
            <SignIn />
          </Route>
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
          <Route path="/create-password">
            <CreatePassword />
          </Route>
          <Route path="/catalog">
            <Catalog />
          </Route>
          <Route path="/product-card">
            <ProductCard />
          </Route>
        </Switch>

        <Footer />
      </>
    );
  }
}

export default App;