import { connect } from 'react-redux';
import SignInSection from './SignInSection';
import {setAccount} from './../../store/accountData/accountAction';

const mapDispatchToProps = {
  setAccount
};

const SignInSectionContainer = connect(null, mapDispatchToProps)(SignInSection);

export default SignInSectionContainer;
