import React, { Component } from 'react';
import './Input.scss';

class Textarea extends Component {

  constructor (props) {
    super(props);

    this.setValue = this.setValue.bind(this);
  }

  setValue (value) {
    this.props.setValue(this.props.name, value);
  }

  render () {

    let { classes, placeholder, label, name, value, isValid, errorMessage} = this.props; 
    return (
      <label>
        {label} 
        <textarea
          className={'' + classes + (isValid == false ? ' invalid' : '')} 
          placeholder={placeholder} 
          name={name}
          value={value}
          onChange={(event) => {
            this.setValue(event.target.value)
          }}
        >
        </textarea>
        <span className="Invalid-messege">
          {errorMessage}
        </span>
      </label>
    );
  }
}

export default Textarea;
