import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import HeaderBlockContainer from './HeaderBlockContainer';
import HeaderBlock_unauthorized from './HeaderBlock_unauthorized';
import Btn from './../Btn/Btn';
import BtnBasket from './BtnBasket';
import HeaderItem from './HeaderItem';
import './Header.scss';

class Header extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isOpened: false,
      filters: {
        categoryId: null,
      }
    }

    this.openedToggle = this.openedToggle.bind(this);
    this.saveToFilters = this.saveToFilters.bind(this);
  }

  openedToggle () {
    this.setState((state) => {
      state.isOpened = !state.isOpened;

      return state;
    })
  }

  saveToFilters(newFilters) {
    this.props.setFilters(newFilters);
  }

  componentDidMount () {

    axios.get('/json/categories.json')
    .then((response) => {
      this.props.setCategoryData(response.data.categories);
    })
    .catch(function (error) {
      
    });

    axios.get('/json/account-unauthed.json')
    .then((response) => {
      if (response.data.status == 200) {
        this.props.setAccount(response.data.account);
        this.setState(state => {

          state.redirect = true;
          window.scrollTo(0, 0);
          
          return state;
        });
      }
    })
    .catch(function (error) {
      
    });
  }
  
  render () {
    return (
      <div className="Header">
        <div className="Container">
          <div className="Header__top-part">
            <div className="Header__left-col">
              {
              this.props.account && this.props.account.userId
                ?
                <HeaderBlockContainer
                  account={this.props.account}
                />
                : 
                <HeaderBlock_unauthorized 
                  signinBtn={this.props.signinBtn}
                  registerBtn={this.props.registerBtn}
                />
              }
            </div>
            <div className="Header__center-col">
              <Link to="/" className="Header__logo">
                <img src={'/images/logo.svg'} alt="Logo" />
              </Link>
            </div>
            <div className="Header__right-col">
              <Btn 
                color='primary' 
                classes="Btn-sm" 
                Btn={this.props.postBtn} 
              />
              {/* <BtnBasket
                Btn={this.props.basketBtn}
                counter='1'
              /> */}
            </div>
          </div>
        </div>
        <div className="Header__bottom-part">
          <div className="Container">
            <div className="Header__toggler" onClick={this.openedToggle}>
              <span className="icon icon-menu"></span>
            </div>
            <div className={"HeaderList " + (this.state.isOpened ? "show" : "")}>
              {
              this.props.categories.length ?
                this.props.categories.map(orderedList => {
                  return (
                    <HeaderItem 
                      key={orderedList.id} 
                      item={orderedList} 
                      saveToFilters={this.saveToFilters}
                    />
                  )
                })
                : null
              }
              </div>
            {/* <a href="#" className="Header__note">
              <span className="icon icon-info"></span>
            </a> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Header; 
