import {
  SET_TODAYS_DEALS_DATA
} from './todaysDealsAction';

const initialState = {
  productsTodaysDeals: []
}

const todaysDealsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TODAYS_DEALS_DATA:
      return {
        ...state,
        productsTodaysDeals: [...action.payload]
      };

      default:
        return state;
  }
}

export default todaysDealsReducer;