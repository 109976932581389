import { connect } from 'react-redux';
import FAQSection from './FAQSection';
import { setFAQData } from './../../store/dataFAQ/FAQAction';

const mapStateToProps = state => {
  return {
    blocks: state.FAQReducer.dataFAQ
  }
};

const mapDispatchToProps = {
  setFAQData
};

const FAQSectionContainer = connect(mapStateToProps, mapDispatchToProps)(FAQSection);

export default FAQSectionContainer;