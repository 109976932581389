import React, { Component } from 'react';
import Subheading from './../../components/Subheading/Subheading';
import BtnViewMore from './../../components/BtnViewMore/BtnViewMore';
import CardList from './CardList';
import './CardSection.scss';

class CardSection extends Component {

  render () {
    return (
      <div className="CardSection">
        <div className="Container offset-top">
          <div className="Subheading__wrapper">
            <Subheading subheading={this.props.subheading} />
            {/* <BtnViewMore link={this.props.pageLink} /> */}
          </div>
          <CardList products={this.props.products} />
        </div>
      </div>
    );
  }
}

export default CardSection;
