import React, { Component } from 'react';
import TextBlock from './../../components/TextBlock/TextBlock';
import './HowItWorksBlock.scss';

class HowItWorksBlock extends Component {

  render () {

    let { img, title, text} = this.props.block;

    return (
      <div className="HowItWorksSection__row">
        <div className="HowItWorksSection__col col-img">
          <img 
            className="HowItWorksSection__img" 
            src={'/images/how-work/' + img + ''} 
            alt="" 
          />
        </div>
        <div className="HowItWorksSection__col">
          <TextBlock 
            classes="border-bottom"
            heading={title}
            text={text}
          />
        </div>
      </div>
    );
  }
}

export default HowItWorksBlock;