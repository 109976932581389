import {
  SET_CONTACT_US_DATA
} from './contactUsAction';

const initialState = {
  dataContactUs: []
}

const contactUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACT_US_DATA: 
      return {
        ...state,
        dataContactUs: action.payload
      };

      default:
        return state;
  }
};

export default contactUsReducer;