import React, { Component } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import './InputSearchLocation.scss';
 
class InputSearchLocation extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      address: '',
      dropdownVisible: false,
      fieldFocused: null,
    };

    this.fieldFocus =     this.fieldFocus.bind(this);
    this.fieldBlur =      this.fieldBlur.bind(this);
    this.setValue =       this.setValue.bind(this);
  }

  setValue (value) {
    this.props.setValue(this.props.name, value);
  }
 
  handleChange = address => {

    this.setState({ 
      address,
    });
  };

  fieldFocus (fieldName) {
    this.setState(state => {
      state.fieldFocused = fieldName

      return state;
    })
  }

  fieldBlur () {
    this.setState(state => {
      state.fieldFocused = null

      return state;
    })
  }
 
  render() {
    let { type, classes, placeholder, label, classesLabel, name, icon, isValid, errorMessage} = this.props;

    return (
      <label className={(icon ? ' Label-with-icon' : '') + (classesLabel ? classesLabel : '')}>
        {label}
          {(icon ? icon : '')}
          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChange}
            name={name}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <input
                  {...getInputProps({
                    type: type,
                    placeholder: placeholder,
                    className: "autocomplete-input " + classes + (icon ? ' Input_with-icon' : '') + (isValid == false ? ' invalid' : ''),
                  })}
                  onKeyDown={() => this.fieldFocus('location')} 
                  onBlur={this.fieldBlur} 
                />
                <div className={"autocomplete-dropdown-container" + (this.state.fieldFocused == 'location' ? ' visible' : '')}>
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {className})}
                      >
                        <span 
                          onClick={() => this.setValue(suggestion.description)}
                        >
                          {suggestion.description}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <span className="Invalid-messege">
            {errorMessage}
          </span>
        </label>
    );
  }
}

export default InputSearchLocation;