import React, { Component } from 'react';
import axios from 'axios';
import Image from './Image';
import './ImageList.scss';

class ImageList extends Component {

  componentDidMount () {
    axios.get('/json/popular-categories.json') 
    .then((response) => {
      this.props.setPopularCategoriesData(response.data.productsPopularCategories)
    })
    .catch(function (error) {
    });
  }

  render () {

    let fourProducts = [];

    for (let i = 1; i <= this.props.productsPopularCategories.length; i++) {
      if ((i % 4) == 0) {
        let fourProduct = [];
        fourProduct.push(this.props.productsPopularCategories[(i - 4)]);
        fourProduct.push(this.props.productsPopularCategories[(i - 3)]);
        fourProduct.push(this.props.productsPopularCategories[(i - 2)]);
        fourProduct.push(this.props.productsPopularCategories[(i - 1)]);

        fourProducts.push(fourProduct);
      }
    }

    return (
      <>
        {
          fourProducts.length ? 
            fourProducts.map((fourProduct, k) => {
              return <div key={k} className="ImageList">
                <div className="ImageList__col first">
                  {<Image key={fourProduct[0].id} product={fourProduct[0]} />}
                  {<Image key={fourProduct[1].id} product={fourProduct[1]} />}
                  {<Image key={fourProduct[2].id} product={fourProduct[2]} />}
                </div>
                <div className="ImageList__col second">
                {<Image key={fourProduct[3].id} product={fourProduct[3]} />}
                </div>
              </div>
            })
          : null
        }
        
      </>
    );
  }
}
  
export default ImageList;