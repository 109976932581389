import { combineReducers }    from 'redux';
import accountReducer         from './accountData/accountReducer';
import categoriesReducer      from './categories/categoriesReducer';
import navigationReducer      from './navigation/navigationReducer';
import popularItemsReducer    from './popularItems/popularItemsReducer';
import recommendationsReducer from './recommendations/recommendationsReducer';
import todaysDealsReducer     from './todaysDeals/todaysDealsReducer';
import popularCategoriesReducer from './popularCategories/popularCategoriesReducer';
import advantagesReducer      from './advantages/advantagesReducer';
import aboutReducer           from './dataAbout/aboutReducer';
import FAQReducer             from './dataFAQ/FAQReducer';
import legalReducer           from './dataLegal/legalReducer';
import contactUsReducer       from './dataContactUs/contactUsReducer';
import howWorksReducer        from './dataHowWorks/howWorksReducer';
import howPostReducer         from './dataHowPost/howPostReducer';
import filtersReducer         from './filters/filtersReducer';
import businessTypesReducer   from './businessTypes/businessTypesReducer';

const rootReducer = combineReducers({
  accountReducer,
  categoriesReducer,
  navigationReducer,
  popularItemsReducer,
  recommendationsReducer,
  todaysDealsReducer,
  popularCategoriesReducer,
  advantagesReducer,
  aboutReducer,
  FAQReducer,
  legalReducer,
  contactUsReducer,
  howWorksReducer,
  howPostReducer,
  filtersReducer,
  businessTypesReducer
});

export default rootReducer;
