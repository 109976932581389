import { connect } from 'react-redux';
import PopularItemsList from './PopularItemsList';
import {setPopularItemsData} from './../../store/popularItems/popularItemsActions';

const mapStateToProps = state => {
  return {
    popularItems: state.popularItemsReducer.popularItems
  }
};

const mapDispatchToProps = {
  setPopularItemsData
};

const PopularItemsListContainer = connect(mapStateToProps, mapDispatchToProps)(PopularItemsList);

export default PopularItemsListContainer;
