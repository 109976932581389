import React, { Component } from 'react';
import axios from 'axios';
import LegalBlock from './LegalBlock';
import Heading from './../../components/Heading/Heading';
import './LegalSection.scss';

class LegalSection extends Component {

  componentDidMount () {

    axios.get('/json/data-legal.json')
    .then((response) => {
      this.props.setLegalData(response.data.dataLegal);
    })
    .catch(function (error) {
      
    });
  }

  render() {

    return (
      <div className="Container offset-top">
        <div className="LegalSection">
          <Heading text="Legal" />
          <div className="LegalSection__content">
            <div className="LegalSection__row">
            {
            this.props.blocks.length ?
              this.props.blocks.map((block, k) => {
                return (<LegalBlock key={k} block={block} />)
              })
              : null
            }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LegalSection;
