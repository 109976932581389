import React, { Component } from 'react';
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import './BtnViewMore.scss';

class BtnViewMore extends Component {

  render () {
    return (
      <Link 
        to={this.props.link} 
        className="BtnViewMore"
        onClick={
          () => {
            animateScroll.scrollToTop({duration: 0})
          }
        }
      >
        View more
        <span className="icon icon-right-arrow"></span>
      </Link>
    );
  }
}

export default BtnViewMore;
