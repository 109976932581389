import { connect } from 'react-redux';
import Header from './Header';
import {setCategoryData} from './../../store/categories/categoriesActions';
import {setAccount} from './../../store/accountData/accountAction';
import {setFilters} from './../../store/filters/filtersAction';


const mapStateToProps = state => {
  return {
    categories: state.categoriesReducer.categories,
    account: state.accountReducer.account,
  }
};

const mapDispatchToProps = {
  setCategoryData,
  setAccount,
  setFilters
};

const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);

export default HeaderContainer;
