import React, { Component } from 'react';

import IntroSection from '../sections/IntroSection/IntroSection';
import PopularItemsListContainer from '../components/PopularItemsList/PopularItemsListContainer';
import CardSectionRecommendationsContainer from '../sections/CardSectionRecommendations/CardSectionRecommendationsContainer';
import CardSectionTodaysDealsContainer from '../sections/CardSectionTodaysDeals/CardSectionTodaysDealsContainer';
import ImageSection from '../sections/ImageSection/ImageSection';
import AdvantageSectionContainer from '../sections/AdvantageSection/AdvantageSectionContainer';
import CTASection from '../sections/CTASection/CTASection';

class Home extends Component {

  render () { 
    return (
      <>
        <IntroSection/>
        <PopularItemsListContainer
          subheading="Popular items"
        />
        
        <CardSectionRecommendationsContainer 
          subheading="Recommendations for you"
        />
        <CardSectionTodaysDealsContainer 
          subheading="Today’s deals"
        />
        <ImageSection
          subheading="Popular Categories"
        />
        <AdvantageSectionContainer /> 
        <CTASection 
          withButton="true"
          CTAButton={this.props.CTAButton}
        />
      </>
    );
  }
}

export default Home;