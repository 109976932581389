import React, { Component } from 'react';
import axios from 'axios';
import { Link, Redirect } from "react-router-dom";
import {validateFieldsUtil, changeState} from './../../utils/utilsForm';
import Heading from './../../components/Heading/Heading';
import BtnSubmit from './../../components/BtnSubmit/BtnSubmit';
import Input from './../../components/Inputs/Input';
import SocialMediaLogin from './../../components/SocialMediaLogin/SocialMediaLogin';
import './SignInSection.scss';

class SignInSection extends Component {

  constructor (props) {
    super(props);

    this.state = { 
      fields: {
        email: '',
        password: ''
      },
      validatesForFields: {
        email: ['required', 'email'],
        password: ['required']
      },
      isValidFields: {
        email: null,
        password: null
      },
      errorFields: {
        email: null,
        password: null
      },
      redirect: false,
    }

    this.setDataToField =   this.setDataToField.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.sendData =         this.sendData.bind(this);
  }

  setDataToField(name, value) {
    this.setState(state => {

      changeState (state, name, value);

      return state;
    });
  }

  handleValidation(){

    this.setState(state => {

      let stateValidated = validateFieldsUtil(state);

      return stateValidated;
    }, () => {

      let isValidFields = true;

      for (let fieldName in this.state.isValidFields) {
        if (!this.state.isValidFields[fieldName]) {
          isValidFields = false;
          break;
        }
      }

      if (isValidFields) {
        this.sendData();
      }
    });
  }

  sendData() {

    axios.get('/json/account.json', {
      params: this.state.fields
    })
    .then((response) => {
      if (response.data.status == 200) {
        this.props.setAccount(response.data.account);
        this.setState(state => {

          state.redirect = true;
          window.scrollTo(0, 0);
          
          return state;
        });
      }
    })
    .catch(function (error) {
      
    });
  }

  render() {

    return (
      <div className="Container offset-top">
        <div className="SignInSection">
          <Heading text="Hello" />
          <div className="Text">
            Sign in to Global Farm or&nbsp;
            <Link to="sign-up" className="Link_green">
              create an account
            </Link>
          </div>
          <form className="Form">
            <div className="Input__row">
              <Input
                type="text"
                classes=""
                isValid={this.state.isValidFields.email} 
                errorMessage={this.state.errorFields.email} 
                placeholder="Email address"
                name="email"
                value={this.state.fields.email}
                setValue={this.setDataToField}
              />
              <Input
                type="password"
                classes="" 
                isValid={this.state.isValidFields.password} 
                errorMessage={this.state.errorFields.password} 
                placeholder="Password"
                name="password"
                value={this.state.fields.password}
                setValue={this.setDataToField}
              />
            </div>
            <div className="Link__wrapper">
              <Link to="reset-password" className="Link_blue">
                Reset your password
              </Link>
            </div>
            <div className="Btn__wrapper">
              <BtnSubmit
                color="primary"
                name="Sign in"
                onClick={this.handleValidation}
              />
              {
                this.state.redirect ?
                <Redirect push to="/" />
                :
                null
              }
            </div>
          </form>
          <SocialMediaLogin />
        </div>
      </div>
    );
  }
}

export default SignInSection;
