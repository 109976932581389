import React, { Component } from 'react';
import TextBlock from './../../components/TextBlock/TextBlock';
import './FAQBlock.scss';

class FAQBlock extends Component {

  render () {

    let { question, answer} = this.props.block;

    return (
      <div className="FAQSection__row">
        <TextBlock 
          heading={question}
          text={answer}
        />
      </div>
    );
  }
}

export default FAQBlock;