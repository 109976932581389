import React, { Component } from 'react';

import CTASection from '../sections/CTASection/CTASection';
import AboutSectionContainer from '../sections/AboutSection/AboutSectionContainer';

class About extends Component {

  render () {
    return (
      <>
        <CTASection />
        <AboutSectionContainer />
      </>
    );
  }
}

export default About;