import React, { Component } from 'react';
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import './HeaderItem.scss';

class HeaderItem extends Component {

  render () {

    let { name, link, id } = this.props.item;
    return (
      <div className="HeaderItem">
        <Link 
          to={link} 
          className="HeaderLink"
          onClick={
            () => {
              animateScroll.scrollToTop({duration: 0});
              this.props.saveToFilters({ categoryId: id });
            }
          }
        >
          {name}
        </Link>
      </div>
    );
  }
}

export default HeaderItem;
