import {
  SET_CATEGORY_DATA
} from './categoriesActions';

const initialState = {
  categories: []
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORY_DATA:
      return {
        ...state,
        categories: action.payload
      };

    default:
      return state;
  }
};

export default categoriesReducer;
