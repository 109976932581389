import React, { Component } from 'react';
import axios from 'axios';
import {validateFieldsUtil, changeState} from '../../utils/utilsForm';
import BtnSubmit from './../../components/BtnSubmit/BtnSubmit';
import InputWithLabel from './../../components/Inputs/InputWithLabel';
import Textarea from './../../components/Inputs/Textarea';
import './ContactUsForm.scss';

class ContactUsForm extends Component {

  constructor (props) {
    super(props);
    this.state = {
      fields: {
        firstName: '',
        lastName: '',
        email: '',
        message: '',
      },
      validatesForFields: {
        firstName: ['required'],
        lastName: [],
        email: ['required', 'email'],
        message: ['required'],
      },
      isValidFields: {
        firstName: null,
        lastName: null,
        email: null,
        message: null,
      },
      errorFields: {
        firstName: null,
        lastName: null,
        email: null,
        message: null,
      }
    }

    this.setDataToField =   this.setDataToField.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.sendData =         this.sendData.bind(this);
  }

  setDataToField(name, value) {
    this.setState(state => {

      changeState (state, name, value);

      return state;
    })
  }

  handleValidation(){

    this.setState(state => {

      let stateValidated = validateFieldsUtil(state);

      return stateValidated;
    }, () => {

      let isValidFields = true;

      for (let fieldName in this.state.isValidFields) {
        if (!this.state.isValidFields[fieldName]) {
          isValidFields = false;
          break;
        }
      }

      if (isValidFields) {
        this.sendData();
      }
    });
  }

  sendData() {
    axios.get('/json/send-data.json', {
      params: this.state.fields
    })
    .then((response) => {
      if (response.data.status == 200) {
        this.setState(state => {

          this.props.openModal('success');
          
          return state;
        });
      }
    })
    .catch(function (error) {
      this.setState(state => {
        
        this.props.openModal('error');

        return state;
      });
    });
  }

  render () {

    return (
      <div className="Container offset-top">
        <div className="Container">
          <form className="ContactUsForm">
            <div className="InputWithLabel__row">
              <InputWithLabel
                label="First name" 
                type="text"
                classes=""
                isValid={this.state.isValidFields.firstName} 
                errorMessage={this.state.errorFields.firstName} 
                placeholder="First name"
                name="firstName"
                value={this.state.fields.firstName}
                setValue={this.setDataToField}
              />
              <InputWithLabel 
                label="Last name" 
                type="text"
                classes=""
                placeholder="Last name" 
                name="lastName"
                value={this.state.fields.lastName}
                setValue={this.setDataToField}
              />
              <InputWithLabel 
                label="Email address" 
                type="text"
                classes=""
                isValid={this.state.isValidFields.email} 
                errorMessage={this.state.errorFields.email} 
                placeholder="Email address" 
                name="email"
                value={this.state.fields.email}
                setValue={this.setDataToField}
              />
            </div>
            <div className="InputWithLabel__row">
              <Textarea
                label="Your message" 
                type="text" 
                classes="Textarea"
                isValid={this.state.isValidFields.message} 
                errorMessage={this.state.errorFields.message} 
                placeholder="Type here"
                name="message"
                value={this.state.fields.message}
                setValue={this.setDataToField}
              />
            </div>
            <div className="Btn__wrapper">
              <BtnSubmit
                color="primary"
                name="Send message"
                onClick={this.handleValidation}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ContactUsForm; 