import React, { Component } from 'react';
import axios from 'axios';
import Heading from './../../components/Heading/Heading';
import Contact from './Contact';
import './ContactUsSection.scss';

class ContactUsSection extends Component { 

  componentDidMount () {

    axios.get('/json/data-contact.json')
    .then((response) => {
      this.props.setContactUsData(response.data.dataContactUs);
    })
    .catch(function (error) {
      
    });
  }

  render () {
    return (
      <div className="Container offset-top">
        <div className="ContactUsSection">
          <Heading text="Contact us" />
          <div className="Contact__row">
            {
              this.props.contacts.length ?
              this.props.contacts.map((contact, k) => {
                return (<Contact key={k} contact={contact} />)
              })
              : null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUsSection;