import React, { Component } from 'react';

import ResetPasswordSection from '../sections/ResetPasswordSection/ResetPasswordSection';

class ResetPassword extends Component {

  render () {
    return (
      <>
        <ResetPasswordSection />
      </>
    );
  }
}

export default ResetPassword;