import { connect } from 'react-redux';
import LegalSection from './LegalSection';
import { setLegalData } from './../../store/dataLegal/legalAction';

const mapStateToProps = state => {
  return {
    blocks: state.legalReducer.dataLegal
  }
};

const mapDispatchToProps = {
  setLegalData
};

const LegalSectionContainer = connect(mapStateToProps, mapDispatchToProps)(LegalSection);

export default LegalSectionContainer;