import React, { Component } from 'react';
import SearchFormContainer from './../../components/SearchForm/SearchFormContainer';
import './SearchFormSection.scss';

class SearchFormSection extends Component {

  render() {

    return ( 
      <div className="SearchFormSection Container">
        <SearchFormContainer
          setSearch={this.props.setSearch}
        />
      </div>
    );
  }
}

export default SearchFormSection;
