import React, { Component } from 'react';

import FitersSectionContainer from './../FitersSection/FitersSectionContainer';
import CardSectionResultsContainer from './../CardSectionResults/CardSectionResultsContainer';
import './CatalogSection.scss';

class CatalogSection extends Component {

  render () { 
    return (
      <div className="CatalogSection Container">
        <FitersSectionContainer />
        <CardSectionResultsContainer />
      </div>
    );
  }
}

export default CatalogSection;