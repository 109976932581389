import { connect } from 'react-redux';
import AdvantageSection from './AdvantageSection';
import { setAdvantagesData } from './../../store/advantages/advantagesAction';

const mapStateToProps = state => {
  return {
    advantages: state.advantagesReducer.advantages
  }
};

const mapDispatchToProps = {
  setAdvantagesData
};

const AdvantageSectionContainer = connect(mapStateToProps, mapDispatchToProps)(AdvantageSection);

export default AdvantageSectionContainer;
