import {
  SET_FAQ_DATA
} from './FAQAction';

const initialState = {
  dataFAQ: []
}

const FAQReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FAQ_DATA: 
      return {
        ...state,
        dataFAQ: action.payload
      };

      default:
        return state;
  }
};

export default FAQReducer;