import React, { Component } from 'react';
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import './Card.scss';

class Card extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isFavorite: false
    }

    this.favoriteToggle = this.favoriteToggle.bind(this);
  }

  favoriteToggle () {
    this.setState((state) => {
      state.isFavorite = !state.isFavorite;

      return state;
    })
  }

  render () {

  let { link, img, name, location, price, unit } = this.props.product;
  return (
    <div className="Card">
      <Link to={link}
        className="Card__img" 
        style={{ backgroundImage: 'url(/images/cards-img/' + img + ')'}}
        onClick={
          () => {
            animateScroll.scrollToTop({duration: 0})
          }
        }
      ></Link>
      <div className="Card__body">
        <div className="Card__row">
          <Link to={link} 
          className="Card__title"
          onClick={
            () => {
              animateScroll.scrollToTop({duration: 0})
            }
          }
          >
            {name}
          </Link>
          <div className="Card__text">
            {location}
          </div>
          <div className="Card__checkbox">
            <input 
              className="Check-input" 
              type="checkbox"
              checked={this.state.isFavorite}
              name="productName" 
              value="productName" 
            />
            <label className="Check-label" onClick={this.favoriteToggle}>
              <span className="Checkbox-icon icon icon-star"></span>
              <span className="Checkbox-icon-chosen icon icon-star-filled"></span>
            </label>
          </div>
        </div>
        <div className="Card__price">
          ${price}
          <span className="Card__unit">
            /{unit}
          </span>
        </div>
      </div>
    </div>
    );
  }
}

export default Card;
