import {
  SET_HOW_WORKS_DATA
} from './howWorksAction';

const initialState = {
  dataHowWorks: []
}

const howWorksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOW_WORKS_DATA: 
      return {
        ...state,
        dataHowWorks: action.payload
      };

      default:
        return state;
  }
};

export default howWorksReducer;