import { connect } from 'react-redux';
import FitersSection from './FitersSection';
import {setFilters, resetFilters} from './../../store/filters/filtersAction';

const mapStateToProps = state => {
  return {
    filters: state.filtersReducer
  }
};

const mapDispatchToProps = {
  setFilters,
  resetFilters
};

const FitersSectionContainer = connect(mapStateToProps, mapDispatchToProps)(FitersSection);

export default FitersSectionContainer;