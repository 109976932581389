import React, { Component } from 'react';
import './Placeholder.scss';

class Placeholder extends Component {

  render () {

    return (
      <div className="Placeholder">
        <span className="icon icon-marker"></span>
       </div>
    );
  }
}

export default Placeholder;