import { connect } from 'react-redux';
import HeaderBlock_authorized from './HeaderBlock_authorized';
import {setNavigationData} from './../../store/navigation/navigationActions';

const mapStateToProps = state => {
  return {
    navigation: state.navigationReducer.navigation
  }
};

const mapDispatchToProps = {
  setNavigationData
};

const HeaderBlock_authorizedContainer = connect(mapStateToProps, mapDispatchToProps)(HeaderBlock_authorized);

export default HeaderBlock_authorizedContainer;
