import {
  SET_BUSINESS_TYPES_DATA
} from './businessTypesActions';

const initialState = {
  businessTypes: []
};

const businessTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUSINESS_TYPES_DATA:
      return {
        ...state,
        businessTypes: action.payload
      };

    default:
      return state;
  }
};

export default businessTypesReducer;
