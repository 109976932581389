import React, { Component } from 'react';
import FooterHeading from './FooterHeading';
import FooterItem from './FooterItem';
import './FooterList.scss';

class FooterList extends Component {

  render () {

    let { heading, classes} = this.props;

    return (
      <div className="FooterList">
        <FooterHeading name={heading} />
        <div className={"FooterItemList " + classes}>
          {
          this.props.categories.length ?
            this.props.categories.map((orderedList, k) => {
              return (
              <FooterItem 
                key={k} 
                orderedList={orderedList} 
              />)
            })
            : null
          }
        </div>
      </div>
    );
  }
}

export default FooterList;