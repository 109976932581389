import React, { Component } from 'react';
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import './Btn.scss';

class Btn extends Component {

  render () {

    let { name, link, onClick} = this.props.Btn;
    let { color, classes, icon} = this.props;
    
    return (
      <Link to={link}
        className={'Btn '+ color +' '+ (classes ? classes : '')}
        onClick={ onClick,
          () => {
            animateScroll.scrollToTop({duration: 0})
          }
        }
      >
        {(icon ? icon : "")}
        {name}
      </Link>
    );
  }
}

export default Btn;
