import { connect } from 'react-redux';
import BtnFacebookLogin from './BtnFacebookLogin';
import {setAccount} from './../../../store/accountData/accountAction';

const mapDispatchToProps = {
  setAccount
};

const BtnFacebookLoginContainer = connect(null, mapDispatchToProps)(BtnFacebookLogin);

export default BtnFacebookLoginContainer;
