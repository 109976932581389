import React, { Component } from 'react';
import BtnFacebookLoginContainer from './BtnFacebookLogin/BtnFacebookLoginContainer';
import BtnGoogleLoginContainer from './BtnGoogleLogin/BtnGoogleLoginContainer';
import './SocialMediaLogin.scss';

class SocialMediaLogin extends Component {

  render() {

    return (
      <>
          <div className="Auth-social__wrapper">
            <div className="Auth-social__label">
              <span>or</span>
            </div>
          </div>
          <div className="BtnLogin__wrapper">
            <BtnFacebookLoginContainer />
            <BtnGoogleLoginContainer />
          </div>
      </>
    );
  }
}

export default SocialMediaLogin;
