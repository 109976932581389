import React, { Component } from 'react';
import axios from 'axios';
import { Link, Redirect } from "react-router-dom";
import {validateFieldsUtil, changeState} from '../../utils/utilsForm';
import Heading from '../../components/Heading/Heading';
import BtnSubmit from './../../components/BtnSubmit/BtnSubmit';
import Input from './../../components/Inputs/Input';
import './CreatePasswordSection.scss';

class CreatePasswordSection extends Component {

  constructor (props) {
    super(props);
    this.state = {
      fields: {
        createPassword: '',
        confirmPassword: ''
      },
      validatesForFields: {
        createPassword: ['required'],
        confirmPassword: ['required', 'verificPassword']
      },
      passwordFieldsConnect: {
        confirmPassword: 'createPassword'
      },
      isValidFields: {
        createPassword: null,
        confirmPassword: null
      },
      errorFields: {
        createPassword: null,
        confirmPassword: null
      },
      redirect: false
    }

    this.setDataToField =   this.setDataToField.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.sendData =         this.sendData.bind(this);
  }

  setDataToField(name, value) {
    this.setState(state => {
      
      changeState (state, name, value);

      return state;
    })
  }

  handleValidation(){

    this.setState(state => {

      let stateValidated = validateFieldsUtil(state);

      return stateValidated;
    }, () => {

      let isValidFields = true;

      for (let fieldName in this.state.isValidFields) {
        if (!this.state.isValidFields[fieldName]) {
          isValidFields = false;
          break;
        }
      }

      if (isValidFields) {
        this.sendData();
      }
    });
  }

  sendData() {
    axios.get('/json/send-data.json', {
      params: this.state.fields
    })
    .then((response) => {
      if (response.data.status == 200) {
        this.setState(state => {

          state.redirect = true;
          window.scrollTo(0, 0);
          
          return state;
        });
      }
    })
    .catch(function (error) {
      
    });
  }

  render() {

    return (
      <div className="Container offset-top">
        <div className="CreatePasswordSection">
          <Heading text="Create a new password" />
          <form className="Form">
            <div className="Input__row">
              <Input
                type="password"
                classes=""
                isValid={this.state.isValidFields.createPassword} 
                errorMessage={this.state.errorFields.createPassword} 
                placeholder="Create new password"
                name="createPassword"
                value={this.state.fields.createPassword}
                setValue={this.setDataToField}
              />
              <Input
                type="password"
                classes=""
                isValid={this.state.isValidFields.confirmPassword} 
                errorMessage={this.state.errorFields.confirmPassword} 
                placeholder="Confirm new password"
                name="confirmPassword"
                value={this.state.fields.confirmPassword}
                setValue={this.setDataToField}
              />
            </div>
            <div className="Btn__wrapper">
              <BtnSubmit
                color="primary"
                name="Save password"
                onClick={this.handleValidation}
              />
              {
                this.state.redirect ?
                <Redirect push to="sign-in" />
                :
                null
              }
            </div>
          </form>
          <div className="Link__wrapper">
            <Link to="sign-in" className="Link_blue">
              Cancel
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatePasswordSection;
