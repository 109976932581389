import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import {validateFieldsUtil, changeState} from '../../utils/utilsForm';
import Modal from './../../components/Modal/Modal';
import Heading from './../../components/Heading/Heading';
import BtnSubmit from './../../components/BtnSubmit/BtnSubmit';
import Input from './../../components/Inputs/Input';
import './ResetPasswordSection.scss';

class ResetPasswordSection extends Component {

  constructor (props) {
    super(props);
    this.state = {
      fields: {
        email: '',
      },
      validatesForFields: {
        email: ['required', 'email']
      },
      isValidFields: {
        email: null
      },
      errorFields: {
        email: null
      },
      modalVisible: false,
      modalType: 'success',
    }

    this.hideModal =        this.hideModal.bind(this);
    this.setDataToField =   this.setDataToField.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.sendData =         this.sendData.bind(this);
  }

  hideModal () {
    this.setState((state) => {
      state.modalVisible = !state.modalVisible;

      return state;
    })
  }

  setDataToField(name, value) {
    this.setState(state => {

      changeState (state, name, value);

      return state;
    })
  }

  handleValidation(){

    this.setState(state => {

      let stateValidated = validateFieldsUtil(state);

      return stateValidated;
    }, () => {

      let isValidFields = true;

      for (let fieldName in this.state.isValidFields) {
        if (!this.state.isValidFields[fieldName]) {
          isValidFields = false;
          break;
        }
      }

      if (isValidFields) {
        this.sendData();
      }
    });
  }

  sendData() {
    axios.get('/json/send-data.json', {
      params: this.state.fields
    })
    .then((response) => {
      if (response.data.status == 200) {
        this.setState(state => {

          state.modalVisible = true;
          state.modalType = 'success';
          
          return state;
        });
      }
    })
    .catch(function () {
      this.setState(state => {
        
        state.modalType = 'error';

        return state;
      });
    });
  }

  render() {

    return (
      <div className="Modal__wrapper">
        <div className={"Container offset-top" + (this.state.modalVisible ? ' hidden' : '')}>
          <div className="ResetPasswordSection">
            <Heading text="Forgot your password?" />
            <div className="Text">
              Enter your email address and we will send you an email to reset your password
            </div>
            <form className="Form">
              <div className="Input__row">
                <Input
                  type="text"
                  classes="w-100"
                  isValid={this.state.isValidFields.email} 
                  errorMessage={this.state.errorFields.email} 
                  placeholder="Email address"
                  name="email"
                  value={this.state.fields.email}
                  setValue={this.setDataToField}
                />
              </div>
              <div className="Btn__wrapper">
                <BtnSubmit
                  color="primary"
                  name="Send email"
                  onClick={this.handleValidation}
                />
              </div>
            </form>
            <div className="Link__wrapper">
              <Link to="sign-in" className="Link_blue">
                Cancel
              </Link>
            </div>
          </div>
        </div>
        {
          this.state.modalVisible ?
          <Modal 
            modalType={this.state.modalType}
            redirect="/create-password"
            onClick={this.hideModal}
          />
          :
          null
        }
      </div>
    );
  }
}

export default ResetPasswordSection;
