import { connect } from 'react-redux';
import CardSectionMoreFromSeller from './CardSectionMoreFromSeller';
import { setTodaysDealsData } from './../../store/todaysDeals/todaysDealsAction';

const mapStateToProps = state => {
  return {
    products: state.todaysDealsReducer.productsTodaysDeals
  }
};

const mapDispatchToProps = {
  setTodaysDealsData
};

const CardSectionMoreFromSellerContainer = connect(mapStateToProps, mapDispatchToProps)(CardSectionMoreFromSeller);

export default CardSectionMoreFromSellerContainer;