import { connect } from 'react-redux';
import HowItWorksSection from './HowItWorksSection';
import { setHowWorksData } from './../../store/dataHowWorks/howWorksAction';

const mapStateToProps = state => {
  return {
    blocks: state.howWorksReducer.dataHowWorks
  }
};

const mapDispatchToProps = {
  setHowWorksData
};

const HowItWorksSectionContainer = connect(mapStateToProps, mapDispatchToProps)(HowItWorksSection);

export default HowItWorksSectionContainer;