import React, { Component } from 'react';

import SearchFormSection from './../sections/SearchFormSection/SearchFormSection';
import ProductCardSection from './../sections/ProductCardSection/ProductCardSection';
import CardSectionMoreFromSellerContainer from './../sections/CardSectionMoreFromSeller/CardSectionMoreFromSellerContainer';
import CardSectionYouLikeContainer from './../sections/CardSectionYouLike/CardSectionYouLikeContainer';
import AdvantageSectionContainer from './../sections/AdvantageSection/AdvantageSectionContainer';

class ProductCard extends Component {

  render () { 
    return (
      <>
        <SearchFormSection />
        <ProductCardSection />
        <CardSectionMoreFromSellerContainer 
          subheading="More from this seller"
        />
        <CardSectionYouLikeContainer 
          subheading="You may also like"
        />
        <AdvantageSectionContainer />
      </>
    );
  }
}

export default ProductCard;