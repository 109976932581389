import React, { Component } from 'react';
import './SearchInput.scss';

class SearchInput extends Component {

  constructor (props) {
    super(props);

    this.setValue = this.setValue.bind(this);
  }

  setValue (value) {
    this.props.setValue(this.props.name, value);
  }

  render () {

    let { placeholder, icon, name, value} = this.props;
    return (
      <div className="Input__wrapper">
        {(this.props.icon ? icon : '')}
        <input 
          type='text' 
          className={'' + (this.props.icon ? ' Input_with-icon' : '')} 
          placeholder={placeholder} 
          name={name}
          value={value}
          onChange={(event) => {
            this.setValue(event.target.value)
          }}
        />
      </div>  
    );
  }
}

export default SearchInput;
