import React, { Component } from 'react';
import axios from 'axios';
import { Link, Redirect } from "react-router-dom";
import {validateFieldsUtil, changeState} from './../../utils/utilsForm';
import Heading from './../../components/Heading/Heading';
import BtnSubmit from './../../components/BtnSubmit/BtnSubmit';
import Input from './../../components/Inputs/Input';
import Recaptcha from './../../components/Recaptcha/Recaptcha';
import SocialMediaLogin from './../../components/SocialMediaLogin/SocialMediaLogin';
import './SignUpSection.scss';

class SignUpSection extends Component {

  constructor (props) {
    super(props);
    this.state = {
      fields: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        agreementChecked: false,
        captchaToken: '',
      },
      validatesForFields: {
        firstName: ['required'],
        lastName: ['required'],
        email: ['required', 'email'],
        password: ['required'],
        agreementChecked: ['checkbox'],
        captchaToken: ['required'],
      },
      isValidFields: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        agreementChecked: null,
        captchaToken: true,
      },
      errorFields: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        agreementChecked: null,
        captchaToken: null,
      },
      redirect: false
    }

    this.checkedToggle =      this.checkedToggle.bind(this);
    this.setDataToField =     this.setDataToField.bind(this);
    this.setCaptchaToken =    this.setCaptchaToken.bind(this);
    this.handleValidation =   this.handleValidation.bind(this);
    this.sendData =           this.sendData.bind(this);
  }

  checkedToggle () {
    this.setState((state) => {
      state.fields.agreementChecked = !state.fields.agreementChecked;
      state.isValidFields.agreementChecked = !state.isValidFields.agreementChecked;

      return state;
    })
  }

  setDataToField(name, value) {
    this.setState(state => {

      changeState (state, name, value);

      return state;
    })
  }

  setCaptchaToken(value) {
    this.setState(state => {
      state.fields.captchaToken = value;
      state.isValidFields.captchaToken = true;
      return state;
    });
  }
  
  handleValidation(redirectOnSuccess){

    this.setState(state => {

      let stateValidated = validateFieldsUtil(state);

      return stateValidated;
    }, () => {

      let isValidFields = true;

      for (let fieldName in this.state.isValidFields) {
        if (!this.state.isValidFields[fieldName]) {
          isValidFields = false;
          break;
        }
      }

      if (isValidFields) {
        this.sendData(redirectOnSuccess);
      }
    });
  }

  sendData() {
    axios.get('/json/send-data.json', {
      params: this.state.fields
    })
    .then((response) => {
      if (response.data.status == 200) {
        this.setState(state => {

          state.redirect = true;
          window.scrollTo(0, 0);
          
          return state;
        });
      }
    })
    .catch(function (error) {
      
    });
  }

  render() {

    return (
      <div className="Container offset-top">
        <div className="SignUpSection">
          <Heading text="Create an account" />
          <div className="Text">
            All member&nbsp;
            <Link to="sign-in" className="Link_green">
              login
            </Link>
          </div>
          <form className="Form">
            <div className="Input__row">
              <Input
                type="text"
                classes=""
                isValid={this.state.isValidFields.firstName} 
                errorMessage={this.state.errorFields.firstName} 
                placeholder="First name"
                name="firstName"
                value={this.state.fields.firstName}
                setValue={this.setDataToField}
              />
              <Input 
                type="text"
                classes=""
                isValid={this.state.isValidFields.lastName} 
                errorMessage={this.state.errorFields.lastName} 
                placeholder="Last name" 
                name="lastName"
                value={this.state.fields.lastName}
                setValue={this.setDataToField}
              />
            </div>
            <div className="Input__row">
              <Input
                type="text"
                classes="" 
                isValid={this.state.isValidFields.email} 
                errorMessage={this.state.errorFields.email} 
                placeholder="Email address"
                name="email"
                value={this.state.fields.email}
                setValue={this.setDataToField}
              />
              <Input
                type="password"
                classes=""
                isValid={this.state.isValidFields.password} 
                errorMessage={this.state.errorFields.password}
                placeholder="Create password"
                name="password"
                value={this.state.fields.password}
                setValue={this.setDataToField}
              />
            </div>
            <div className="Checkbox__row">
              <div className="Checkbox__wrapper">
                <input 
                  type="checkbox" 
                  className="Checkbox__input"
                  name="agreementChecked"
                  checked={this.state.fields.agreementChecked}
                />
                <label 
                  className={"Checkbox__label" + (this.state.isValidFields.agreementChecked == false ? ' invalid' : '') +''}
                  onClick={this.checkedToggle}
                >
                  <span className="icon icon-check"></span>
                </label>
              </div>
              <div className="Description">
                By Registering, you agree that you’ve read and accepted our User Agreement, you’re at&nbsp;least 18&nbsp;years old, and you consent to our Privacy Notice and receiving marketing communications from&nbsp;us.
              </div>
            </div>
              <Recaptcha
                description="Before you proceed please complete the captcha below"
                isValid={this.state.isValidFields.captchaToken} 
                onChange={this.setCaptchaToken}
              />
            <div className="Btn__wrapper">
              <BtnSubmit
                color="primary"
                name='Create an account'
                onClick={this.handleValidation}
              />
              {
                this.state.redirect ?
                <Redirect push to="confirm-account" />
                :
                null
              }
            </div>
          </form>
          <SocialMediaLogin />
        </div>
      </div>
    );
  }
}

export default SignUpSection;
