import React, { Component } from 'react';
import FooterHeading from './FooterHeading';
import FooterItemCategories from './FooterItemCategories';
import './FooterList.scss';

class FooterListCategories extends Component {

  constructor (props) {
    super(props);
    this.state = {
      filters: {
        categoryId: null,
      }
    }

    this.saveToFilters = this.saveToFilters.bind(this);
  }

  saveToFilters(newFilters) {
    this.props.setFilters(newFilters);
  }

  render () {

    let { heading, classes} = this.props;

    return (
      <div className="FooterList">
        <FooterHeading name={heading} />
        <div className={"FooterItemList " + classes}>
          {
          this.props.categories.length ?
            this.props.categories.map((orderedList, k) => {
              return (
              <FooterItemCategories
                key={k} 
                item={orderedList}
                saveToFilters={this.saveToFilters}
              />)
            })
            : null
          }
        </div>
      </div>
    );
  }
}

export default FooterListCategories;