import { connect } from 'react-redux';
import CardSectionTodaysDeals from './CardSectionTodaysDeals';
import { setTodaysDealsData } from './../../store/todaysDeals/todaysDealsAction';

const mapStateToProps = state => {
  return {
    products: state.todaysDealsReducer.productsTodaysDeals
  }
};

const mapDispatchToProps = {
  setTodaysDealsData
};

const CardSectionTodaysDealsContainer = connect(mapStateToProps, mapDispatchToProps)(CardSectionTodaysDeals);

export default CardSectionTodaysDealsContainer;