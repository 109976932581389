import React, { Component } from 'react';

import ConfirmAccountSection from '../sections/ConfirmAccountSection/ConfirmAccountSection';

class ConfirmAccount extends Component {

  render () {
    return (
      <>
        <ConfirmAccountSection />
      </>
    );
  }
}

export default ConfirmAccount;