import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './../Btn/Btn.scss';

class BtnBasket extends Component {

  render () {

    let { name, link } = this.props.Btn;
    return (
      <Link to={link} className="Btn BtnBasket Btn-sm secondary">
        <img className="" alt="" src="./images/basket.svg"></img>
        <span className="Btn__text">
          {name}
        </span>&nbsp;
        <span className="Counter">
					({this.props.counter})
				</span>
      </Link>
    );
  }
}

export default BtnBasket;
