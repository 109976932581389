import { connect } from 'react-redux';
import CardSectionResults from './CardSectionResults';
import { setRecommendationsData } from './../../store/catalog/recommendationsAction';

const mapStateToProps = state => {
  return {
    products: state.recommendationsReducer.productsRecomendations,
    priceRange: state.filtersReducer.priceRange,
    sortOption: state.filtersReducer.sortOption,
    categoryId: state.filtersReducer.categoryId,
  }
};

const mapDispatchToProps = {
  setRecommendationsData
};

const CardSectionResultsContainer = connect(mapStateToProps, mapDispatchToProps)(CardSectionResults);

export default CardSectionResultsContainer;