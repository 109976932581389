import {
  SET_LEGAL_DATA
} from './legalAction';

const initialState = {
  dataLegal: []
}

const legalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEGAL_DATA: 
      return {
        ...state,
        dataLegal: action.payload
      };

      default:
        return state;
  }
};

export default legalReducer;