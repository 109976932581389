import React, { Component } from 'react';

import FAQSectionContainer from '../sections/FAQSection/FAQSectionContainer';

class FAQ extends Component {

  render () {
    return (
      <>
        <FAQSectionContainer />
      </>
    );
  }
}

export default FAQ;