import React, { Component } from 'react';
import Card from './Card';
import './CardList.scss';

class CardList extends Component {

  render () {
    return (
      <div className="CardList">
        {
        this.props.products.length ?
          this.props.products.map(product => {
            return (<Card key={product.id} product={product} />)
          })
          : null
        }
      </div>
    );
  }
}

export default CardList;