import React, { Component } from 'react';
import './ProductCardSection.scss';

class ProductCardSection extends Component {

  render() {

    return ( 
      <div className="Container offset-top">
        <div className="ProductCard">
          <div className="ProductCard__left-col">
            <div className="ProductCard__img"> 
              <img src={'/images/cards-img/img6.jpg'} alt="Product" />
            </div>
          </div>
          <div className="ProductCard__right-col">
            <div className="ProductCard__title">
              Gorizia
            </div>
            <div className="ProductCard__desc">
              Product description, dolor esse eu minim incididunt aliquip dolore magna eiusmod 
              incididunt mollit et occaecat nisi ex aute elit velit cupidatat eiusmod excepteur 
              incididunt nulla in tempor enim minim incididunt velit tempor irure mollit voluptate 
              consequat commodo.
            </div>
            <ul className="ProductCard__list">
              <li>Immediate shipping</li>
              <li>Minimum quantity: 10kgs</li>
              <li>Available in April and March</li>
              <li>Shipped from: Asia Pacific</li>
              <li>Buy one, get one free</li>
            </ul>
            <div className="ProductCard__certified">
              <img src="/images/certified.svg" alt="Check" />
              Organic certified
            </div>
            <div className="ProductCard__price">
              $56.99-$60.99&nbsp;/kg&#8194;&#8194;
              <span className="ProductCard__type-price">
                Price&nbsp;Fixed
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductCardSection;
