import React, { Component } from 'react';
import TextBlock from './../../components/TextBlock/TextBlock';
import './LegalBlock.scss';

class LegalBlock extends Component {

  render () {

    let { title, text} = this.props.block;

    return (
      <div className="LegalSection__col">
        <TextBlock 
          heading={title}
          text={text}
        />
      </div>
    );
  }
}

export default LegalBlock;