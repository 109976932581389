import {
  SET_HOW_POST_DATA
} from './howPostAction';

const initialState = {
  dataHowPost: []
}

const howPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOW_POST_DATA: 
      return {
        ...state,
        dataHowPost: action.payload
      };

      default:
        return state;
  }
};

export default howPostReducer;