import {
  SET_ADVANTAGES_DATA
} from './advantagesAction';

const initialState = {
  advantages : []
};

const advantagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADVANTAGES_DATA:
      return {
        ...state,
        advantages: action.payload
      };

    default:
      return state;
  }
};

export default advantagesReducer;