import React, { Component } from 'react';
import axios from 'axios';
import CardSection from './../CardSection/CardSection';

class CardSectionTodaysDeals extends Component {
  
  componentDidMount () {

    axios.get('/json/todays-deals.json')
    .then((response) => {
      this.props.setTodaysDealsData(response.data.productsTodaysDeals);
    })
    .catch(function (error) {
      
    });
  }

  render () {
    return (
      <>
        <CardSection 
          subheading={this.props.subheading}
          products={this.props.products} 
        />
      </>
    );
  }
}

export default CardSectionTodaysDeals;