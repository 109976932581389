import React, { Component } from 'react';
import axios from 'axios';
import {Redirect } from "react-router-dom";
import FacebookLogin from 'react-facebook-login';
import './BtnFacebookLogin.scss';

class BtnFacebookLogin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      facebookResponse: {},
      redirect: false,
    }

    this.responseFacebook =   this.responseFacebook.bind(this);
  }
  
  responseFacebook = (response) => {

    this.setState(state => {
      state.facebookResponse = response;
      return state;
    });

    axios.get('/json/account.json', {
      params: this.state.facebookResponse
    })
    .then((response) => {
      if (response.data.status == 200) {
        this.props.setAccount(response.data.account);
        this.setState(state => {

          state.redirect = true;
          window.scrollTo(0, 0);
          
          return state;
        });
      }
    })
    .catch(function (error) {
      
    });
  }

  render () {
    
    return (
      <div className="BtnFacebookLogin__wrapper">
        <FacebookLogin
          appId="1088597931155576"
          fields="name,email,picture"
          cssClass="BtnFacebookLogin"
          icon={<i className="fab fa-facebook-f"></i>}
          textButton="Sign in with Facebook"
          callback={this.responseFacebook}
        >
        </FacebookLogin>
        {
          this.state.redirect ?
          <Redirect push to="/" />
          :
          null
        }
      </div>
    );
  }
}

export default BtnFacebookLogin;