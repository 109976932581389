import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './../Inputs/Input.scss';
import './Dropdown.scss';

class Dropdown extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isOpened: false,
    }

    this.openedToggle =     this.openedToggle.bind(this);
    this.closedToggle =     this.closedToggle.bind(this);
  }

  openedToggle () {
    this.setState((state) => {
      state.isOpened = !state.isOpened;

      return state;
    })
  }

  closedToggle () {
    this.setState((state) => {
      state.isOpened = false;

      return state;
    })
  }

  render () {

    let { classes, icon, name, options, hasNotice} = this.props;

    return (
      <div
        className="Dropdown__wrapper"
        tabIndex="0"
        onBlur={this.closedToggle}
      > 
        <div
          className={'Btn ' + (classes ? classes : '') + (hasNotice ? ' Btn-notice' : '')}
          onClick={this.openedToggle}
        >
          {(icon ? icon : "")}
          {name}
        </div>
        <div className={'DropdownMenu' + (this.state.isOpened ? ' open' : '')}>
        {
          options.length ?
          options.map((option, k) => {
            return (
              <Link to="/"
                key={k}
                className="DropdownItem"
                onClick={this.openedToggle}
              >
                {option.name}
              </Link>
            )
          })
          : null
        }
        </div>
      </div>
    );
  }
}

export default Dropdown;
