import React, { Component } from 'react';

import SignInSectionContainer from '../sections/SignInSection/SignInSectionContainer';

class SignIn extends Component {

  render () {
    return (
      <>
        <SignInSectionContainer />
      </>
    );
  }
}

export default SignIn;