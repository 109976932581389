import React, { Component } from 'react';
import axios from 'axios';
import CardList from './../CardSection/CardList';
import NoResultSection from './../NoResultSection/NoResultSection'
import './CardSectionResults.scss';

class CardSectionResults extends Component {
  
  componentDidMount () {

    axios.get('/json/catalog.json')
    .then((response) => {
      this.props.setRecommendationsData(response.data.productsRecomendations);
    })
    .catch(function (error) {
      
    });
  }

  render () {

    let filteredProducts = this.props.products.slice();
    
    if (!this.props.priceRange || this.props.priceRange == 'All' ) {
      filteredProducts = this.props.products.slice();
    } else {
      filteredProducts = this.props.products.filter(product => {
        return product.price < this.props.priceRange;
      });
    }

    let selectedProductCategoryId = this.props.categoryId;
    
    if (this.props.categoryId) {
      filteredProducts = filteredProducts.filter(product => {
        return product.productCategoryId == selectedProductCategoryId;
      });
    }

    let sortOption = this.props.sortOption;

    if (sortOption) {
      filteredProducts = filteredProducts.sort(function (a, b) {

        if (sortOption === 'asc') {
          if (a.price > b.price) {
            return 1;
          }
          if (a.price < b.price) {
            return -1;
          }
          return 0;
        }
        else if (sortOption === 'desc') {
          if (a.price < b.price) {
            return 1;
          }
          if (a.price > b.price) {
            return -1;
          }
          return 0;
        }
      });
    }

    return (
      <div className="CardSectionResults">
        {
          (filteredProducts.length) ?
          <CardList products={filteredProducts} />
          : <NoResultSection />
        }
      </div>
    );
  }
}

export default CardSectionResults;