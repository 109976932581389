import React, { Component } from 'react';
import axios from 'axios';
import Item from './Item';
import './Item.scss';
import './PopularItemsList.scss';

class PopularItemsList extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isOpened: false
    }

    this.openedToggle = this.openedToggle.bind(this);
  }

  openedToggle () {
    this.setState((state) => {
      state.isOpened = !state.isOpened;

      return state;
    })
  }

  componentDidMount () {

    axios.get('/json/items-list.json')
    .then((response) => {
      this.props.setPopularItemsData(response.data.popularItems);
    })
    .catch(function (error) {
      
    });
  }


  render () {

    return (
      <div className="PopularItemsList__wraaper">
        <div className="Container">
          <div className="PopularItemsList__container">
            <div className="Item Item__subheading mob">
              {this.props.subheading}
            </div>
            <div className="Item__toggler" onClick={this.openedToggle}>
              <span className="icon icon-menu"></span>
            </div>
            <div className={"PopularItemsList " + (this.state.isOpened ? "show" : "") + ""}>
              <div className="Item Item__subheading desc">
                {this.props.subheading}
              </div>
              {
                this.props.popularItems.length ?
                this.props.popularItems.map(item => {
                  return (<Item key={item.id} item={item} />)
                })
                : null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PopularItemsList;
