import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import {validateFieldsUtil, changeState} from '../../utils/utilsForm';
import Heading from '../../components/Heading/Heading';
import BtnSubmit from './../../components/BtnSubmit/BtnSubmit';
import Input from './../../components/Inputs/Input';
import './ConfirmAccountSection.scss';

class ConfirmAccountSection extends Component {

  constructor (props) {
    super(props);
    this.state = {
      fields: {
        password: '',
        confirmPassword: ''
      },
      validatesForFields: {
        password: ['required'],
        confirmPassword: ['required', 'verificPassword']
      },
      passwordFieldsConnect: {
        confirmPassword: 'password'
      },
      isValidFields: {
        password: null,
        confirmPassword: null
      },
      errorFields: {
        password: null,
        confirmPassword: null
      },
      redirect: false
    }

    this.setDataToField =   this.setDataToField.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.sendData =         this.sendData.bind(this);
  }

  setDataToField(name, value) {
    this.setState(state => {

      changeState (state, name, value);

      return state;
    })
  }

  handleValidation(){

    this.setState(state => {

      let stateValidated = validateFieldsUtil(state);

      return stateValidated;
    }, () => {

      let isValidFields = true;

      for (let fieldName in this.state.isValidFields) {

        if (!this.state.isValidFields[fieldName]) {
          isValidFields = false;
          break;
        }
      }

      if (isValidFields) {
        this.sendData();
      }
    });
  }

  sendData() {
    axios.get('/json/send-data.json', {
      params: this.state.fields
    })
    .then((response) => {
      if (response.data.status == 200) {
        this.setState(state => {

          state.redirect = true;
          window.scrollTo(0, 0);
          
          return state;
        });
      }
    })
    .catch(function (error) {
      
    });
  }

  render() {

    return (
      <div className="Container offset-top">
        <div className="ConfirmAccountSection">
          <Heading text="Confirm your account" />
          <form className="Form">
            <div className="Input__row">
              <Input
                type="password"
                classes=""
                isValid={this.state.isValidFields.password} 
                errorMessage={this.state.errorFields.password} 
                placeholder="Enter password"
                name="password"
                value={this.state.fields.password}
                setValue={this.setDataToField}
              />
              <Input
                type="password"
                classes=""
                isValid={this.state.isValidFields.confirmPassword} 
                errorMessage={this.state.errorFields.confirmPassword} 
                placeholder="Enter password, again"
                name="confirmPassword"
                value={this.state.fields.confirmPassword}
                setValue={this.setDataToField}
              />
            </div>
            <div className="Btn__wrapper">
              <BtnSubmit
                color="primary"
                name="Set-up my profile"
                onClick={this.handleValidation}
              />
              {
                this.state.redirect ?
                <Redirect push to="set-up" />
                :
                null
              }
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ConfirmAccountSection;
