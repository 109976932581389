import React, { Component } from 'react';

import SetUpSectionContainer from '../sections/SetUpSection/SetUpSectionContainer';

class SetUp extends Component {

  render () {
    return (
      <>
        <SetUpSectionContainer />
      </>
    );
  }
}

export default SetUp;