import React, { Component } from 'react';

import CreatePasswordSection from '../sections/CreatePasswordSection/CreatePasswordSection';

class CreatePassword extends Component {

  render () {
    return (
      <>
        <CreatePasswordSection />
      </>
    );
  }
}

export default CreatePassword;