import React, { Component } from 'react';
import './TextBlock.scss';

class TextBlock extends Component {

  render () {

    let { heading, text, classes} = this.props;
    
    return (
      <div 
        className={'TextBlock '+ (classes ? classes : '')}
      >
        <div className="TextBlock__heading">
          {heading}
        </div>
        <div className="TextBlock__content">
          <p>
            {text}
          </p>
        </div>
      </div>
    );
  }
}

export default TextBlock;
