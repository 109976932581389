import React, { Component } from 'react';
import axios from 'axios';
import Dropdown  from './../Dropdown/Dropdown';

class HeaderBlock_authorized extends Component {

  componentDidMount () {

    axios.get('/json/navigation.json')
    .then((response) => {
      this.props.setNavigationData(response.data.navigation);
    })
    .catch(function () {
      
    });
  }

  render () {
    return (
      <>
        <Dropdown
          name={<span className="Btn__text">{this.props.account.userName}</span>}
          icon={<span className="Btn__icon icon icon-profile"></span>}
          classes="Btn-sm secondary BtnDropdown"
          options={this.props.navigation}
        />
        <Dropdown
          icon={<span className="icon icon-bell"></span>}
          classes="Btn-xs secondary"
          options={this.props.navigation}
          hasNotice={this.props.account.userNotifications}
        />
        <Dropdown
          icon={<span className="icon icon-email"></span>}
          classes="Btn-xs secondary"
          options={this.props.navigation}
          hasNotice={this.props.account.userMessages}
        />
      </>
    );
  }
}

export default HeaderBlock_authorized;