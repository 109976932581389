import React, { Component } from 'react';
import './Heading.scss';

class Heading extends Component {

  render () {

    return (
      <div className="Heading">
        <h3> 
          { this.props.text }
        </h3>
      </div>
    );
  }
}

export default Heading;