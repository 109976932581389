import React, { Component } from 'react';
import './Input.scss';

class Input extends Component {

  constructor (props) {
    super(props);

    this.setValue = this.setValue.bind(this);
  }

  setValue (value) {
    this.props.setValue(this.props.name, value);
  }

  render () {

    let { type, classes, placeholder, icon, name, value, isValid, errorMessage} = this.props; 
    return (
      <div className="Input__group">
        {(icon ? icon : '')}
        <input 
          type={type} 
          className={classes + (icon ? ' Input_with-icon' : '') + (isValid == false ? ' invalid' : '')} 
          placeholder={placeholder} 
          name={name}
          value={value}
          onChange={(event) => {
            this.setValue(event.target.value)
          }}
        />
        <span className="Invalid-messege">
          {errorMessage}
        </span>
      </div>
    );
  }
}

export default Input;
