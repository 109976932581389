import { connect } from 'react-redux';
import SetUpSection from './SetUpSection';
import {setBusinessTypesData} from './../../store/businessTypes/businessTypesActions';
import {setAccount} from './../../store/accountData/accountAction';

const mapStateToProps = state => {
  return {
    options: state.businessTypesReducer.businessTypes
  }
};

const mapDispatchToProps = {
  setBusinessTypesData,
  setAccount
};

const SetUpSectionContainer = connect(mapStateToProps, mapDispatchToProps)(SetUpSection);

export default SetUpSectionContainer;
