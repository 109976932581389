import React, { Component } from 'react';
import Subheading from './../../components/Subheading/Subheading';
import BtnViewMore from './../../components/BtnViewMore/BtnViewMore';
import ImageListContainer from './ImageListContainer';

class ImageSection extends Component {

  render () {
    return (
      <div className="ImageSection">
        <div className="Container offset-top">
          <div className="Subheading__wrapper">
            <Subheading subheading={this.props.subheading} />
            {/* <BtnViewMore link={this.props.pageLink} /> */}
          </div>
          <ImageListContainer />
        </div>
      </div>
    );
  }
}

export default ImageSection;
