import React, { Component } from 'react';
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import './FooterItem.scss';

class FooterItem extends Component {

  render () {

    let { name, link, round, single, scrollDuration } = this.props.orderedList;
    return (
      <Link 
        to={link}
        onClick={
          this.props.onClick,
          () => {
            animateScroll.scrollToTop({duration: (scrollDuration ? scrollDuration : 0)})
          }
        }
        className={"FooterItem " + (round ? "FooterItem__round " : "") + (single ? "go-top" : "")} 
      >
        {name}
      </Link>
    );
  }
}

export default FooterItem;
