import { connect } from 'react-redux';
import AboutSection from './AboutSection';
import { setAboutData } from './../../store/dataAbout/aboutAction';

const mapStateToProps = state => {
  return {
    blocks: state.aboutReducer.dataAbout
  }
};

const mapDispatchToProps = {
  setAboutData
};

const AboutSectionContainer = connect(mapStateToProps, mapDispatchToProps)(AboutSection);

export default AboutSectionContainer;