import { connect } from 'react-redux';
import BtnGoogleLogin from './BtnGoogleLogin';
import {setAccount} from './../../../store/accountData/accountAction';

const mapDispatchToProps = {
  setAccount
};

const BtnGoogleLoginContainer = connect(null, mapDispatchToProps)(BtnGoogleLogin);

export default BtnGoogleLoginContainer;
