import React, { Component } from 'react';

import SignUpSection from '../sections/SignUpSection/SignUpSection';

class SignUp extends Component {

  render () {
    return (
      <>
        <SignUpSection />
      </>
    );
  }
}

export default SignUp;