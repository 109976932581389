import React, { Component } from 'react';
import './Subheading.scss';

class Subheading extends Component {

  render () {

    return (
      <div className="Subheading">
        <h3>
          { this.props.subheading }
        </h3>
      </div>
    );
  }
}

export default Subheading;