import React, { Component } from 'react';
import SearchFormContainer from './../../components/SearchForm/SearchFormContainer';
import './IntroSection.scss';

class IntroSection extends Component {

  render() {

    return ( 
      <div className="IntroSection__wrapper">
        <div className="Container">
          <div className="IntroSection">
            <h1>
              If it’s organic, custom, or&nbsp;unique, it’s on&nbsp;Global Farm.
            </h1>
            <SearchFormContainer
              setSearch={this.props.setSearch}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default IntroSection;
